import { createAsyncThunk, createSlice, AnyAction } from '@reduxjs/toolkit';

import { create } from 'utils/api';
import { Status } from 'store/types';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { REACT_APP_BUYER_URL } from 'constants/config';
import handleErrorMessage from 'helpers/handleErroreMessage';

import { PreAuthRequestParams, CheckPhoneParams } from './types';

export interface PreLoginState {
  status: Status;
  preAuthSuccess: boolean;
  phoneIsValid: boolean;
  phoneNumber: string;
  errorMessage: null | string;
}

const initialState: PreLoginState = {
  status: 'idle',
  preAuthSuccess: false,
  phoneIsValid: false,
  errorMessage: null,
  phoneNumber: '0000000000',
};

const checkPhonePreLogin = async (params: CheckPhoneParams, { rejectWithValue }: any) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/pre-login`, params);
    localStorage.removeItem('cartProducts');
    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const checkPhonePreLoginThunk = createAsyncThunk('preLogin/checkPhone', checkPhonePreLogin);

const preAuthLoggedOut = async (params: PreAuthRequestParams & CheckPhoneParams) => {
  const response = create(`${REACT_APP_BUYER_URL}/pre-auth`, { ...params });
  return response;
};

export const preAuthLoggedOutThunk = createAsyncThunk('logout/pre-login', preAuthLoggedOut);

export const PreLoginSlice = createSlice({
  name: 'preLogin',
  initialState,
  reducers: {
    resetPreLogin: () => initialState,
    resetPreAuthState: (state: PreLoginState) => {
      const { preAuthSuccess } = initialState;
      return {
        ...state,
        preAuthSuccess,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(preAuthLoggedOutThunk.fulfilled.type, (state: PreLoginState, action: any) => {
        const {
          meta: {
            arg: { phoneNumber },
          },
        } = action;

        return {
          ...state,
          preAuthSuccess: true,
          phoneNumber,
        };
      })
      .addCase(checkPhonePreLoginThunk.fulfilled.type, (state: PreLoginState) => {
        return {
          ...state,
          phoneIsValid: true,
          status: 'success',
        };
      })
      .addCase(checkPhonePreLoginThunk.pending.type, (state: PreLoginState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(checkPhonePreLoginThunk.rejected.type, (state: PreLoginState, action: AnyAction) => {
        const errorMessage = handleErrorMessage(action.payload);
        return {
          ...state,
          errorMessage,
          status: 'failed',
        };
      });
  },
});

export const usePreLoginData = (): PreLoginState => {
  const reducerState = useAppSelector((state: RootState) => state.preLogin);
  return reducerState;
};

export default PreLoginSlice.reducer;
export const { resetPreAuthState, resetPreLogin } = PreLoginSlice.actions;
