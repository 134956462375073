import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { SubCategoryInterface, Category } from './types';

export const getCategory = async (id: number) => {
  const response = await read(`${REACT_APP_BUYER_URL}/categories/${id}`);
  return response;
};

interface CategoryState {
  subCategory: SubCategoryInterface;
}

export const getCategoryThunk = createAsyncThunk('category/subCategorieslist', getCategory);

const initialState = {
  subCategory: { data: null, status: 'idle' },
} as CategoryState;

const CategoriySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getCategoryThunk.fulfilled.type,
        (state: CategoryState, action: PayloadAction<Category>) => {
          return {
            ...state,
            subCategory: {
              data: action.payload,
              status: 'success',
            },
          };
        }
      )
      .addCase(getCategoryThunk.pending.type, (state: CategoryState) => {
        return {
          ...state,
          subCategory: {
            ...state.subCategory,
            status: 'loading',
          },
        };
      });
  },
});

export const useCategoryData = (): CategoryState => {
  const reducerState = useAppSelector((state: RootState) => state.categories);
  return reducerState;
};

export const { reset } = CategoriySlice.actions;
export default CategoriySlice.reducer;
