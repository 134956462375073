import { changeProductStatusThunk, getShoppingCartThunk } from 'store/cart';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';

export const returnShoppingCartPreviousItemsState = (dispatch, getShoppingCart = true) => {
  const preiouesCartItems = localStorage.getItem('shoppingCartPreviousData');
  if (preiouesCartItems) {
    const cartItems = JSON.parse(preiouesCartItems);
    const activeItems = [];
    cartItems.forEach(({ id, isActive }) => {
      if (isActive) {
        activeItems.push(id);
      }
    });

    if (activeItems.length) {
      const data = {
        isActive: true,
        shopingCartIds: activeItems,
      };

      dispatch(changeProductStatusThunk({ data }));
      if (getShoppingCart) {
        const [lat, lng] = JSON.parse(localStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
        dispatch(getShoppingCartThunk({ lat, lng }));
      }
    }
    localStorage.removeItem('shoppingCartPreviousData');
  }
};
