import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  AppBar,
  Button,
  Link,
  OutlinedInput,
  Typography,
  Grid,
  Box,
  IconButton,
  FormControl,
} from '@mui/material';
import classNames from 'classnames';

import { readUserDataThunk, useBuyerData } from 'store/buyer/editBuyerData';
import { readUserData as readSellerData, useSellerData } from 'store/account/seller';
import { authStorage } from 'store/storage';
import {
  resetShoppingCartStatus,
  getLoggedOutUserCartCountThunk,
  useShoppingCart,
} from 'store/cart';
import Form from 'components/Form';
import Fb from 'components/FB';
import ErrorBoundary from 'components/ErrorBoundary';
import AddProductInShoppingCardAlert from 'components/AddProductInShoppingCardAlert/AddProductInShoppingCardAlert';
import CreateProductReviewAlert from 'components/CreateProductReviewAlert/CreateProductReviewAlert';
import CheckDeliveryDatesAlert from 'components/CheckDeliveryDatesAlert';
import CheckShoppingCartAlert from 'components/CheckShoppingCartAlert';
import { getSearchPage } from 'helpers/routeHelper';
import userTypes from 'constants/userTypes';
import ROUTES from 'constants/routes';

import Catalog from './Catalog';
import GeolocationMap from './GeolocationMap';
import ProfileMenu from './ProfileMenu';

import { ReactComponent as Heart } from 'assets/icons/heart.svg';
import { ReactComponent as MaxMarketHeaderLogo } from 'assets/icons/maxmarket-header-logo.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icone.svg';
import { ReactComponent as ShoppingCartIcon } from 'assets/icons/shoppingCartHeader.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

import { socialLinks } from './constants';

import './index.scss';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const {
    data: { shop, avatar: sellerAvatar = '' },
  } = useSellerData();
  const { data: { wishlistProductCount = 0, avatar: buyerAvatar = '' } = {} } = useBuyerData();
  const { count } = useShoppingCart();

  const { logo: shopLogo = '' } = shop || {};

  const [headerBoxShadow, setHeaderBoxShadow] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState('');
  const anchorRef = useRef<HTMLDivElement>(null);

  const { DASHBOARD, PRODUCTS } = ROUTES;

  const { buyer } = userTypes;
  const { userType } = authStorage.get();
  const isLoggedIn = !!authStorage.get().accessToken;
  const isBuyerType = userType === buyer;

  useEffect(() => {
    if (!isLoggedIn && localStorage.getItem('cartProducts') && pathname !== ROUTES.CART) {
      let cartProducts: any = localStorage.getItem('cartProducts');
      if (cartProducts) {
        cartProducts = JSON.parse(cartProducts);

        cartProducts = Object.values(cartProducts) || [];
        const products = [];
        cartProducts.forEach(data => {
          data.forEach(({ id, count }) => {
            products.push({
              id,
              count,
            });
          });
        });

        if (products.length) {
          dispatch(getLoggedOutUserCartCountThunk({ products }));
        }
      }
    }
  }, [isLoggedIn, pathname, localStorage.getItem('cartProducts')]);

  useEffect(() => {
    const changeBoxShadow = () => {
      if (window.scrollY) {
        setHeaderBoxShadow(true);
      } else {
        setHeaderBoxShadow(false);
      }
    };
    window.addEventListener('scroll', changeBoxShadow);
    return () => {
      window.removeEventListener('scroll', changeBoxShadow);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (isBuyerType) {
        dispatch(readUserDataThunk(''));
      } else {
        dispatch(readSellerData());
      }
      return () => {
        dispatch(resetShoppingCartStatus());
      };
    }
  }, [isLoggedIn, pathname]);

  const handleRedirectToWishlist = () => {
    navigate('/account/wishlist');
  };

  useEffect(() => {
    if (pathname === PRODUCTS) {
      const params = new URLSearchParams(search);
      if (params.has('q')) {
        const query = params.get('q');
        setSearchQuery(query);
      } else {
        setSearchQuery('');
      }
    } else {
      searchQuery && setSearchQuery('');
    }
  }, [pathname, search]);

  const handleSearchProducts = () => {
    const params = new URLSearchParams(search);
    const bannerToken = params.get('bannerToken');

    navigate(getSearchPage(searchQuery, bannerToken));
  };

  const handleClickLogo = () => {
    window.scrollTo({ top: 0 });
    navigate(DASHBOARD);
  };

  const moveToShoppingCart = useCallback(() => {
    if (!(!isBuyerType && isLoggedIn)) {
      navigate(ROUTES.CART, {
        state: {
          buyNow: false,
          refreshThePage: Date.now(),
        },
      });
    }
  }, [isBuyerType, isLoggedIn]);

  return (
    <AppBar
      className="mobile_container_grid_h"
      sx={{
        backgroundColor: '#fcfdfd',
        height: '100px',
        boxShadow: 'none',
      }}
    >
      <AddProductInShoppingCardAlert />
      <CheckDeliveryDatesAlert />
      <CheckShoppingCartAlert />
      <CreateProductReviewAlert />
      <div
        className={classNames('header-default mobile_container_grid_h', {
          'header-scroll': headerBoxShadow,
        })}
      >
        <Fb
          justifyContent="center"
          sx={{
            backgroundColor: '#F2F7FA',
            width: '100%',
            height: '34px',
            color: '#828282;',
          }}
        >
          <div className="header-info-wrap">
            <GeolocationMap />
            <Fb className="phone-wrapper">
              <div className="vk-tel-wrap">
                {socialLinks.map(item => {
                  const { href, Icon } = item;
                  return (
                    <Link
                      key={href}
                      sx={{ width: '24px', height: '24px' }}
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={Icon} width="24px" />
                    </Link>
                  );
                })}
              </div>
              <>
                <Link
                  className="phone-wrap"
                  href="tel:88005505728"
                  underline="none"
                  sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                >
                  <PhoneIcon width="14px" alt="phone" />
                  <Typography className="phone">8 (800) 550 57 28</Typography>
                  <div className="popap">
                    <div className="popap-arrow"></div>
                    <ClockIcon />
                    <div className="clock-wrap">
                      <p> Пн-Вс: 09:00 - 19:00 мск</p>
                      <p> Звонок бесплатный </p>
                    </div>
                  </div>
                </Link>
              </>
            </Fb>
          </div>
        </Fb>

        <Grid
          container
          justifyContent="center"
          sx={{ height: '66px', width: '1180px', maxWidth: '1440px' }}
        >
          <Grid
            item
            xs={12}
            ref={anchorRef}
            sx={{ height: '66px', display: 'flex', justifyContent: 'start' }}
          >
            <Box sx={{ pb: 2 }}></Box>
            <Grid className="forms-wrapper">
              <Grid item sx={{ alignItems: 'center', display: 'flex' }}>
                <MaxMarketHeaderLogo
                  className="cursor-pointer max-market-img"
                  onClick={handleClickLogo}
                />
              </Grid>
              <ErrorBoundary>
                <Catalog anchorRef={anchorRef} />
              </ErrorBoundary>
              <Grid item flex={4}>
                <Form onSubmit={handleSearchProducts}>
                  <FormControl focused={false} fullWidth>
                    <Fb className="header-search-wrapper">
                      <OutlinedInput
                        className="header-search-input"
                        value={searchQuery}
                        onChange={event => setSearchQuery(event.target.value)}
                      />
                      <Button type="submit" disableElevation className="header-search-button">
                        <SearchIcon width={24} height={24} />
                      </Button>
                    </Fb>
                  </FormControl>
                </Form>
              </Grid>

              {isBuyerType && (
                <div className="wishlist-count" onClick={handleRedirectToWishlist}>
                  <Heart className="header-heart" />
                  <div className="count">
                    {wishlistProductCount >= 99 ? `99+` : wishlistProductCount}{' '}
                  </div>
                </div>
              )}

              <Grid item className="avatar-wrapper">
                <Fb
                  flexGrow={0}
                  alignItems="center"
                  width={118}
                  height={44}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className={classNames('cart-icon-wrapper', {
                      'cursor-pointer': !(!isBuyerType && isLoggedIn),
                    })}
                    onClick={moveToShoppingCart}
                  >
                    <IconButton
                      disableFocusRipple={true}
                      disabled={!isBuyerType && isLoggedIn}
                      className="cart-icon-wrapper"
                      sx={{
                        p: 0,
                        width: '44px',
                        height: '44px',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                        },
                      }}
                    >
                      <ShoppingCartIcon
                        className={isBuyerType || !isLoggedIn ? '' : 'cart-disabled'}
                        width={24}
                        height={24}
                      />
                    </IconButton>
                    {(isBuyerType || !isLoggedIn) && (
                      <div className="count">{count >= 99 ? `99+` : count} </div>
                    )}
                  </div>
                  <ProfileMenu {...{ buyerAvatar, sellerAvatar, shopLogo }} />
                </Fb>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </AppBar>
  );
};

export default Header;
