import {
  AnyAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit';

import { create, read, update, edit } from 'utils/api';
import { useAppSelector } from 'store/hooks';
import { setShowShoppingCartWarning } from 'store/order';
import { readUserDataThunk } from 'store/buyer/editBuyerData';
import { RootState } from 'store/index';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';

import {
  CartState,
  RemoveFromCartPayload,
  ChangeStatusFromCartPayload,
  ProductsDeliveryDurationsInterface,
  GetShoppingCartrequestParams,
  ShowWarning,
  AddProductInTheShoppingCartRequestParams,
  GetOutOfDeliveryParams,
} from './types';

export const checkOutOfDelivery = async ({ lat, lng }: GetOutOfDeliveryParams) => {
  const response = await read(`${REACT_APP_BUYER_URL}/in-no-delivery-zone?lat=${lat}&lng=${lng}`);

  const { outOfDelivery } = response as any;

  return outOfDelivery;
};

const getShoppingCartDurationsForLoggedOutUser = async (
  { lat, lng, products }: ProductsDeliveryDurationsInterface,
  { dispatch }: any
) => {
  const outOfDelivery = await checkOutOfDelivery({ lat, lng });

  const response = await create(`${REACT_APP_BUYER_URL}/products/delivery-duration`, {
    lat,
    lng,
    products,
  });
  let initialCount = 0;
  let count = 0;
  const result = {};
  const { data } = response;

  products.forEach(({ count }) => {
    initialCount += count;
  });

  Object.entries(data).forEach(([shop, products]: any) => {
    if (!result[shop]) {
      result[shop] = [];
    }

    products.forEach(product => {
      const {
        count: productCount,
        product: { id: productId },
        isActive,
      } = product;
      count += productCount;
      const existingIndex = result[shop]?.findIndex(({ id }) => id === productId);

      if (existingIndex > -1) {
        result[shop][existingIndex] = {
          ...result[shop][existingIndex],
          count: result[shop][existingIndex].count + 1,
        };
      } else {
        result[shop].push({
          id: productId,
          count: 1,
          isActive,
          product: {
            id: productId,
          },
        });
      }
    });
  });

  if (initialCount > count) {
    dispatch(
      setShowShoppingCartWarning({
        showCheckShoppingCartWarning: true,
        checkShoppingCartText:
          'Количество товаров в вашей корзине изменилось, проверьте корзину перед оформлением заказа.',
      })
    );

    localStorage.setItem('cartProducts', JSON.stringify(result));
  }
  return { data, count, outOfDelivery };
};

export const getShoppingCartDurationsForLoggedOutUserThunk = createAsyncThunk(
  'products/deliery-durations',
  getShoppingCartDurationsForLoggedOutUser
);

const getLoggedOutUserCartCount = async ({ products }) => {
  const response = await create(`${REACT_APP_BUYER_URL}/products/count-in-cart`, { products });
  return response;
};

export const getLoggedOutUserCartCountThunk = createAsyncThunk(
  'loggedOutUser/cart/count',
  getLoggedOutUserCartCount
);

const addProductCountInShoppingCart = async (
  data: AddProductInTheShoppingCartRequestParams,
  { dispatch }
) => {
  const { productId, makeActive = false, refreshShoppingCart = false, callback } = data;
  try {
    const response: any = await update(`${REACT_APP_BUYER_URL}/shopping-cart`, { productId });
    const { id } = response || {};
    if (makeActive) {
      dispatch(
        changeProductStatusThunk({
          data: {
            isActive: true,
            shopingCartIds: [id],
          },
        })
      );
    }
    if (refreshShoppingCart) {
      const [lat, lng] = JSON.parse(localStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
      await dispatch(getShoppingCartThunk({ lat, lng, showLoading: false }));
    } else {
      dispatch(readUserDataThunk(''));
    }
    callback && callback();
    return response;
  } catch (err) {
    callback && callback();
  }
};

export const addProductCountInShoppingCartThunk = createAsyncThunk(
  'cart/addCount',
  addProductCountInShoppingCart
);

export const getShoppingCart = async ({ lat, lng }: GetShoppingCartrequestParams) => {
  let url = `${REACT_APP_BUYER_URL}/shopping-cart`;
  if (lat) {
    url += `?lat=${lat}`;
  }
  if (lng) {
    url += `&lng=${lng}`;
  }

  const outOfDelivery = await checkOutOfDelivery({ lat, lng });

  const response = await read(url);

  return { ...response, outOfDelivery };
};

export const getShoppingCartThunk = createAsyncThunk('cart/get', getShoppingCart);

const removeFromCart = async (
  { shopingCartIds, callback }: RemoveFromCartPayload,
  { dispatch }
) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/delete-cart-products`, {
      shopingCartIds,
    });
    const [lat, lng] = JSON.parse(localStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
    await dispatch(getShoppingCartThunk({ lat, lng, showLoading: false }));
    callback && callback();
    return response;
  } catch (err) {
    callback && callback();
  }
};

export const removeFromShoppingCartThunk = createAsyncThunk('cart/remove', removeFromCart);

const changeProductStatus = async (
  { data, getShoppingCart }: ChangeStatusFromCartPayload,
  { dispatch }
) => {
  const response = await edit(`${REACT_APP_BUYER_URL}/change-product-status`, data);
  // callback && callback();

  if (getShoppingCart) {
    const [lat, lng] = JSON.parse(localStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
    dispatch(getShoppingCartThunk({ showLoading: false, lat, lng }));
  }

  return response;
};

export const changeProductStatusThunk = createAsyncThunk(
  'cart/changeProductStatus',
  changeProductStatus
);

export const getProductStock = async (id: number) => {
  const response = await read(`${REACT_APP_BUYER_URL}/products/${id}/stock`);
  return response;
};

const initialState: CartState = {
  data: {},
  loggedOutCart: {},
  cartLoading: 'idle',
  count: 0,
  addignToShoppingCartLoadingForProduct: null,
  selectedProducts: [],
  addProductInShoppingCartStatus: 'idle',
  changeProductStatus: 'idle',
  refreshCart: false,
  showCheckoutSection: false,
  showWarningCheckDeliveryDate: 'idle',
  outOfDelivery: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetShoppingCartStatus: (state: CartState) => {
      return {
        ...state,
        cartLoading: initialState.cartLoading,
      };
    },
    setRefreshCart: (state: CartState) => {
      state = current(state);
      return {
        ...state,
        refreshCart: !state.refreshCart,
      };
    },
    setCount: (state: CartState, action) => {
      state.count = action.payload;
    },
    setOutOfDelivery: (state: CartState, action) => {
      state.outOfDelivery = action.payload;
    },
    setShowCheckoutSection: (state: CartState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showCheckoutSection: action.payload,
      };
    },
    setShowWarningCheckDeliveryDate: (state: CartState, action: PayloadAction<ShowWarning>) => {
      return {
        ...state,
        showWarningCheckDeliveryDate: action.payload,
      };
    },
    setLoggedOutcart(state: CartState, action) {
      let count = 0;
      Object.values(action.payload).forEach((products: any) => {
        products.forEach(product => {
          const { count: productCount } = product;
          count += productCount;
        });
      });

      return {
        ...state,
        loggedOutCart: action.payload,
        count,
      };
    },
    addProductToTheShoppingCart(state: CartState, action: any) {
      const { payload: showNotification = true } = action;
      return {
        ...state,
        addProductInShoppingCartStatus: showNotification
          ? 'success'
          : state.addProductInShoppingCartStatus,
        count: state.count + 1,
      };
    },
    resetStatus(state: CartState) {
      return {
        ...state,
        addProductInShoppingCartStatus: 'idle',
      };
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLoggedOutUserCartCountThunk.fulfilled.type, (state: CartState, action: any) => {
        const {
          payload: { count },
        } = action;
        return {
          ...state,
          count,
        };
      })
      .addCase(getShoppingCartThunk.pending.type, (state: CartState, action: any) => {
        const {
          meta: {
            arg: { showLoading = true },
          },
        } = action;

        return {
          ...state,
          cartLoading: showLoading ? 'loading' : state.cartLoading,
        };
      })
      .addCase(getShoppingCartThunk.rejected.type, (state: CartState) => {
        return {
          ...state,
          cartLoading: 'failed',
        };
      })
      .addCase(
        getShoppingCartThunk.fulfilled.type,
        (state: CartState, action: PayloadAction<CartState>) => {
          const {
            payload: { data = {}, outOfDelivery },
          } = action;
          let initialCount = 0;
          const selectedProducts = [];

          Object.values(data).forEach((products: any) => {
            products.forEach(product => {
              const { count, isActive, id } = product;
              if (isActive) {
                selectedProducts.push(id);
              }
              initialCount += count;
            });
          });

          return {
            ...state,
            data,
            outOfDelivery,
            selectedProducts,
            count: initialCount,
            cartLoading: 'success',
            refreshCart: false,
          };
        }
      )
      .addCase(
        getShoppingCartDurationsForLoggedOutUserThunk.rejected.type,
        (state: CartState, action: any) => {
          const {
            meta: {
              arg: { showLoading },
            },
          } = action;

          return {
            ...state,
            cartLoading: showLoading ? 'failed' : state.cartLoading,
          };
        }
      )
      .addCase(
        getShoppingCartDurationsForLoggedOutUserThunk.pending.type,
        (state: CartState, action: any) => {
          const {
            meta: {
              arg: { showLoading },
            },
          } = action;

          return {
            ...state,
            cartLoading: showLoading ? 'loading' : state.cartLoading,
          };
        }
      )
      .addCase(
        getShoppingCartDurationsForLoggedOutUserThunk.fulfilled.type,
        (state: CartState, action: any) => {
          const {
            payload: { data, count, outOfDelivery },
            meta: {
              arg: { showLoading },
            },
          } = action;

          return {
            ...state,
            loggedOutCart: data,
            cartLoading: showLoading ? 'success' : state.cartLoading,
            count,
            outOfDelivery,
            refreshCart: true,
          };
        }
      )
      .addCase(changeProductStatusThunk.pending.type, (state: CartState) => {
        return {
          ...state,
          changeProductStatus: 'loading',
        };
      })
      .addCase(changeProductStatusThunk.rejected.type, (state: CartState) => {
        return {
          ...state,
          changeProductStatus: 'failed',
        };
      })
      .addCase(changeProductStatusThunk.fulfilled.type, (state: CartState, action: any) => {
        state = current(state);
        const {
          data: { shopingCartIds, isActive },
        } = action.meta.arg;

        const cardData = JSON.parse(JSON.stringify(state.data));
        let selectedProducts = [...state.selectedProducts];

        if (!isActive) {
          selectedProducts = selectedProducts.filter(selectedProductId => {
            return !shopingCartIds.includes(selectedProductId);
          });
        } else {
          selectedProducts = [...selectedProducts, ...shopingCartIds];
        }

        Object.values(cardData).forEach((products: any) => {
          products.forEach(product => {
            const { id } = product;
            if (shopingCartIds.includes(id)) {
              product.isActive = isActive;
            }
          });
        });

        return {
          ...state,
          selectedProducts,
          changeProductStatus: 'success',
          data: cardData,
        };
      })
      .addCase(
        addProductCountInShoppingCartThunk.fulfilled.type,
        (state: CartState, action: AnyAction) => {
          const {
            meta: {
              arg: { showNotification = true },
            },
          } = action;
          return {
            ...state,
            addignToShoppingCartLoadingForProduct: null,
            addProductInShoppingCartStatus: showNotification
              ? 'success'
              : state.addProductInShoppingCartStatus,
          };
        }
      )
      .addCase(
        addProductCountInShoppingCartThunk.pending.type,
        (state: CartState, action: AnyAction) => {
          const {
            meta: {
              arg: { productId },
            },
          } = action;
          return {
            ...state,
            addignToShoppingCartLoadingForProduct: productId,
            addProductInShoppingCartStatus: 'loading',
          };
        }
      );
  },
});

export const useShoppingCart = (): CartState => {
  const reducerState = useAppSelector((state: RootState) => state.cart);
  return reducerState;
};

export const getShoppingCartMemoized = createSelector(
  (state: RootState) => state.cart,
  cart => cart
);

export default cartSlice.reducer;
export const {
  setCount,
  setLoggedOutcart,
  reset,
  addProductToTheShoppingCart,
  resetStatus,
  setShowWarningCheckDeliveryDate,
  setShowCheckoutSection,
  setOutOfDelivery,
  setRefreshCart,
  resetShoppingCartStatus,
} = cartSlice.actions;
