import { AnyAction, createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import bannerTypes from 'constants/bannerTypes';
import { isMobile } from 'react-device-detect';

import { DashboardInterface } from './types';

const getDashboardBanners = async () => {
  const response = read(
    `${REACT_APP_BUYER_URL}/banners-by-type/${bannerTypes.TYPE_REGULAR_BANNER}`
  );
  return response;
};

const getDashboardBrands = async () => {
  const response = await read(`${REACT_APP_BUYER_URL}/brands?order=popularity_order:asc`);
  return response;
};

const getDashboardCategories = async () => {
  const response = await read(
    `${REACT_APP_BUYER_URL}/ordered-categories?order=popularity_order:ASC&limit=6`
  );
  return response;
};

const getPopularCategoriesBanners = async () => {
  const response = read(
    `${REACT_APP_BUYER_URL}/banners-by-type/${bannerTypes.TYPE_ADVERTISEMENT_BANNER}?limit=2`
  );
  return response;
};
export const getPopularCategoriesBannersThunk = createAsyncThunk(
  'popular-categories-banners',
  getPopularCategoriesBanners
);

export const getDashboardCategoriesData = createAsyncThunk(
  'dashboardCategory/list',
  getDashboardCategories
);

export const getDashboardBrandsData = createAsyncThunk('dashboardBrands/list', getDashboardBrands);

export const getDashboardBannersThunk = createAsyncThunk('banners', getDashboardBanners);

export const getBestCashbackProducts = async () => {
  const response = await read(
    `${REACT_APP_BUYER_URL}/products?limit=5&order=cashback_maxmoney:DESC`
  );
  return response;
};

export const getBestCashbackProductsThunk = createAsyncThunk(
  'bestCashback/get',
  getBestCashbackProducts
);

export const getRandomProducts = async () => {
  const response = await read(`${REACT_APP_BUYER_URL}/products/random`, {
    params: { limit: isMobile ? 5 : 16 },
  });
  return response;
};

export const getRandomProductsThunk = createAsyncThunk('randomProducts/get', getRandomProducts);

const initialState: DashboardInterface = {
  pageLoading: false,
  banners: {
    data: [],
    meta: {},
    status: 'idle',
  },
  bestCashback: {
    data: [],
    meta: {},
    status: 'idle',
  },
  popularCategoriesBanners: {
    data: [],
    meta: {},
    status: 'idle',
  },
  randomProducts: {
    data: [],
    meta: {},
    status: 'idle',
  },
  brands: {
    data: [],
    meta: {},
    status: 'idle',
  },
  categories: {
    data: [],
    meta: {},
    status: 'idle',
  },
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  reducers: {
    removeFromWishlistInBestCashback: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.bestCashback.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        bestCashback: {
          ...state.bestCashback,
          data,
        },
      };
    },
    changWishlistProductIdInBestCashback: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.bestCashback.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        bestCashback: {
          ...state.bestCashback,
          data,
        },
      };
    },
    removeFromWishlistInRandomProducts: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.randomProducts.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        randomProducts: {
          ...state.randomProducts,
          data,
        },
      };
    },
    changWishlistProductIdInRandomProducts: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.randomProducts.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        randomProducts: {
          ...state.randomProducts,
          data,
        },
      };
    },
    resetDashboardState: () => {
      return { ...initialState, pageLoading: true };
    },
    resetBrnads: (state: DashboardInterface) => {
      return {
        ...state,
        brands: {
          ...initialState.brands,
        },
      };
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        getDashboardBannersThunk.fulfilled.type,
        (state: DashboardInterface, action: any) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            banners: {
              data,
              meta,
              status: 'success',
            },
          };
        }
      )
      .addCase(getDashboardBannersThunk.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          banners: {
            ...state.banners,
            status: 'loading',
          },
        };
      })
      .addCase(getDashboardBannersThunk.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          banners: {
            ...state.banners,
            status: 'failed',
          },
        };
      })
      .addCase(getRandomProductsThunk.fulfilled.type, (state: DashboardInterface, action: any) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          randomProducts: {
            data,
            meta,
            status: 'success',
          },
        };
      })
      .addCase(getRandomProductsThunk.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          randomProducts: {
            ...state.randomProducts,
            status: 'loading',
          },
        };
      })
      .addCase(getRandomProductsThunk.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          randomProducts: {
            ...state.randomProducts,
            status: 'failed',
          },
        };
      })
      .addCase(
        getBestCashbackProductsThunk.fulfilled.type,
        (state: DashboardInterface, action: AnyAction) => {
          const { data, meta } = action.payload;
          return {
            ...state,

            bestCashback: {
              ...state.bestCashback,
              data,
              meta,
              status: 'success',
            },
          };
        }
      )
      .addCase(getBestCashbackProductsThunk.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          bestCashback: {
            ...state.bestCashback,
            status: 'loading',
          },
        };
      })
      .addCase(getBestCashbackProductsThunk.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          bestCashback: {
            ...state.bestCashback,
            status: 'failed',
          },
        };
      })
      .addCase(getDashboardBrandsData.fulfilled.type, (state: DashboardInterface, action: any) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          brands: { data, meta, status: 'success' },
        };
      })
      .addCase(getDashboardBrandsData.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          brands: {
            ...state.brands,
            status: 'loading',
          },
        };
      })
      .addCase(getDashboardBrandsData.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          brands: {
            ...state.brands,
            status: 'failed',
          },
        };
      })
      .addCase(
        getDashboardCategoriesData.fulfilled.type,
        (state: DashboardInterface, action: AnyAction) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            categories: {
              ...state.categories,
              data,
              meta,
            },
          };
        }
      )
      .addCase(
        getPopularCategoriesBannersThunk.fulfilled.type,
        (state: DashboardInterface, action: any) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            popularCategoriesBanners: {
              ...state.popularCategoriesBanners,
              data,
              meta,
            },
          };
        }
      );
  },
});

export const useDashboardData = (): DashboardInterface => {
  const reducerState = useAppSelector((state: RootState) => state.dashboard);
  return reducerState;
};

export default DashboardSlice.reducer;
export const {
  resetBrnads,
  resetDashboardState,
  changWishlistProductIdInBestCashback,
  removeFromWishlistInRandomProducts,
  changWishlistProductIdInRandomProducts,
  removeFromWishlistInBestCashback,
} = DashboardSlice.actions;
