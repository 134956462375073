import { useCallback, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Stack, Typography, Button, InputAdornment } from '@mui/material';

import { useAppDispatch } from 'store/hooks';
import { checkPhonePreLoginThunk, resetPreLogin, usePreLoginData } from 'store/auth/preLogin';
import Form, { FormInput, useForm } from 'components/Form';
import RegistrationAndLoginButtons from 'components/RegistrationAndLoginButtons';
import Fb from 'components/FB';
import { loginSchema } from 'resolvers';
import { defaultParamsPreLogin } from 'constants/auth/preLogin';
import ROUTES from 'constants/routes';
import formattedPhone from 'helpers/formattedPhone';

import Popup from './OTPverification';

import checkCircle from 'assets/icons/check-circle.svg';

const LoginPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const { status, errorMessage, phoneIsValid } = usePreLoginData();

  const { redirectionUrl } = state || {};

  const {
    watch,
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<{
    phoneNumber?: string;
  }>({
    resolver: loginSchema,
    defaultValues: {
      phoneNumber: '',
    },
  });

  useEffect(() => {
    return () => {
      dispatch(resetPreLogin());
    };
  }, []);

  const phoneNumber = watch('phoneNumber');

  const onSubmit = () => {
    dispatch(checkPhonePreLoginThunk({ phoneNumber: +phoneNumber, ...defaultParamsPreLogin }));
  };

  const validationProps = useCallback(
    (field: string) => {
      const fieldError = errors?.[field];
      return {
        name: field,
        error: !!fieldError,
        helperText: fieldError?.message,
      };
    },
    [errors]
  );

  return phoneIsValid ? (
    <Fb justifyCenter alignCenter flex1 sx={{ p: 2 }}>
      <Popup
        redirectionUrl={redirectionUrl}
        text={`Мы отправили код подтверждения на номер +7 ${
          formattedPhone(phoneNumber) || '000 000 00 00'
        }`}
        phoneNumber={+phoneNumber}
      />
    </Fb>
  ) : (
    <Fb justifyCenter alignCenter flex1 sx={{ backgroundColor: '#FFF', pt: 6, pb: 6 }}>
      <Fb sx={{ border: '1px solid #000', px: 8, py: 6, borderRadius: 5 }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <RegistrationAndLoginButtons />
          <Fb justifyCenter sx={{ p: 3 }}>
            <Stack spacing={'40px'} sx={{ width: '320px' }}>
              <Stack spacing="10px">
                <Typography>Введите ваш номер телефона</Typography>
                <FormInput
                  resetField={resetField}
                  control={control}
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                  }}
                  {...validationProps('phoneNumber')}
                />
                <Stack spacing="6px">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={status === 'loading'}
                    disableRipple
                  >
                    Продолжить
                  </Button>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#db287e',
                      height: '40px',
                    }}
                  >
                    {errorMessage}
                  </p>
                </Stack>
              </Stack>

              <Fb alignStart>
                <img src={checkCircle} alt="check-circle-icon" className="check-circle-icon" />
                <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#676767' }}>
                  Согласен с условиями{' '}
                  <Link className="link" to={ROUTES.CONFIDENTIALITY} target="_blank">
                    <u>Правил пользования торговой площадкой и правилами возврата</u>
                  </Link>
                </Typography>
              </Fb>
            </Stack>
          </Fb>
        </Form>
      </Fb>
    </Fb>
  );
};

export default LoginPage;
