import { Fragment, useState } from 'react';

import SingleOtpInput from './SingleOtpInput';

import keyCode, { numInputs } from './constants';

interface Props {
  value: string;
  errorMessage: null | string;
  onChange: (value: string) => void;
}

const OtpInput = ({ value, onChange, errorMessage }: Props): JSX.Element => {
  const [activeInput, setActiveInput] = useState<number>(0);

  const getOtpValue = () => (value ? value.toString().split('') : []);

  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join('');
    onChange(otpValue);
  };

  const isInputValueValid = (value: string) => {
    return !isNaN(parseInt(value, 10)) && value.trim().length === 1;
  };

  const focusInput = (input: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);
    setActiveInput(activeInput);
  };

  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOtpValue();
    otp[activeInput] = value[0];

    handleOtpChange(otp);
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const otp = getOtpValue();

    let newNextActiveInput = activeInput;

    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift();
        if (!isInputValueValid(otp[pos])) return;
        newNextActiveInput++;
      }
    }

    setActiveInput(newNextActiveInput);
    focusInput(newNextActiveInput);
    handleOtpChange(otp);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { BACKSPACE, DELETE, LEFT_ARROW, RIGHT_ARROW, SPACEBAR } = keyCode;
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      changeCodeAtFocus('');
      focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      changeCodeAtFocus('');
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      focusNextInput();
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.key === 'Space'
    ) {
      e.preventDefault();
    }
  };

  const handleOnInput = e => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput();
    } else {
      const { nativeEvent } = e;

      if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
        e.preventDefault();
        changeCodeAtFocus('');
        focusPrevInput();
      }
    }
  };

  const otp = getOtpValue();

  return (
    <div className="otp-input-wrap">
      <div className="otp-inputs">
        {Array(numInputs)
          .fill(0)
          .map((_, i) => {
            return (
              <Fragment key={i}>
                <SingleOtpInput
                  focus={activeInput === i}
                  value={otp && otp[i]}
                  onChange={handleOnChange}
                  onKeyDown={handleOnKeyDown}
                  onPaste={handleOnPaste}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    setActiveInput(i);
                    e.target.select();
                  }}
                  onInput={handleOnInput}
                  onBlur={() => setActiveInput(-1)}
                  isDisabled={false}
                />
                {i !== 3 ? <span className="otp-input-separator"></span> : null}
              </Fragment>
            );
          })}
      </div>
      <p className="error">{errorMessage}</p>
    </div>
  );
};

export default OtpInput;
