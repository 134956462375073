import ROUTES from 'constants/routes';

export const accountPageByType = (type: string) => ROUTES.ACCOUNT.replace(':type', type);

export const generateBrandPageUrl = (id: number | string) =>
  ROUTES.BRAND.replace(':brandID', id.toString());

export const getCategoryPageRoute = (brandId: number | string, categoryId: number | string) => {
  return ROUTES.BRAND_CATEGORIES_LIST.replace(':brandID', brandId.toString()).replace(
    ':categoryID',
    categoryId.toString()
  );
};

export const getActualOfferPageRoute = () => {
  return ROUTES.BRAND_ACTUAL_OFFERS_LIST;
};

export const getSearchPage = (searchQuery: string, bannerToken: string | null) => {
  if (bannerToken) {
    return ROUTES.PRODUCTS + `?bannerToken=${bannerToken}` + `&q=${searchQuery}`;
  } else if (searchQuery) {
    return ROUTES.PRODUCTS + `?q=${searchQuery}`;
  }
  return ROUTES.PRODUCTS;
};
