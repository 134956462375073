import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store/hooks';
import OTPVerificationBox from 'components/OTPVerificationBox';
import {
  checkOTPBuyerRegisterThunk,
  resetBuyerRegistration,
} from 'store/auth/registration/buyerRegistration';
import {
  checkOTPSellerRegisterThunk,
  resetSellerRegistration,
} from 'store/auth/registration/sellerOTP';
import { defaultParamsPreRegistration } from 'constants/auth/preRegistration';
import ROUTES from 'constants/routes';
import { accountPageByType } from 'helpers/routeHelper';
import AccountTabs from 'constants/accountTabs';
import { useEffect } from 'react';

interface Props {
  type?: number;
  text?: string;
  phoneNumber?: number;
}

const VerificationPopup = ({ text, type, phoneNumber }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { countryCode } = defaultParamsPreRegistration;

  const buyerRegistration = data => {
    const callBack = () => {
      navigate(accountPageByType(AccountTabs.PERSONAL_INFO));
    };
    dispatch(checkOTPBuyerRegisterThunk({ data, callBack }));
  };

  useEffect(() => {
    return () => {
      if (type == 1) {
        dispatch(resetBuyerRegistration());
      } else {
        dispatch(resetSellerRegistration());
      }
    };
  }, [type]);

  const sellerRegistration = data => {
    const callBack = () => navigate(ROUTES.SELLER_SIGN_UP);
    dispatch(checkOTPSellerRegisterThunk({ data, callBack }));
  };

  const handleOTPVerification = otp => {
    localStorage.setItem('otp', String(otp));
    if (type == 1) {
      buyerRegistration({ otp, type, phoneNumber, countryCode });
    } else {
      sellerRegistration({ otp, phoneNumber, countryCode });
    }
  };

  return (
    <OTPVerificationBox
      showPrivacyPolicy={true}
      handleOTPVerification={handleOTPVerification}
      phoneNumber={phoneNumber}
      text={text}
      type={type}
    />
  );
};

export default VerificationPopup;
