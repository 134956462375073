import { useNavigate, NavLink } from 'react-router-dom';

import { Grid, Typography, Link, Box } from '@mui/material';

import PaymentOptionsImage from 'assets/icons/payment-options.svg';
import PhoneIcon from 'assets/icons/phoneFooter.svg';
import TelegramIcon from 'assets/icons/telegramIcon.svg';
import MaxMarketLogo from 'assets/icons/maxmarket-header-logo.svg';
import DzenIcon from 'assets/icons/dzen.svg';

import { FooterLinkInterface, footerLinks, footerIcons } from './constants';
import ROUTES from 'constants/routes';
import Fb from 'components/FB';

import './style.scss';

const Footer = () => {
  const navigate = useNavigate();
  const { DASHBOARD } = ROUTES;
  const { forBuyers, newsCompanies, forStores, forPartners }: any = footerLinks;
  const { VKIcon, AddressMarkIcon, MailIcon } = footerIcons;

  return (
    <Grid container className="footer mobile_container_grid_h">
      <Grid item sx={{ width: '1180px', maxWidth: '100%' }}>
        <Fb column alignStart className="footer-text-wrap">
          <Fb
            sx={{ p: 0, mb: 2, width: '80%', height: 'auto' }}
            onClick={() => navigate(DASHBOARD)}
          >
            <img
              className="cursor-pointer"
              src={MaxMarketLogo}
              alt="MaxMarket Logo"
              width="258px"
              height="47px"
            />
          </Fb>
          <Typography className="footer-text">
            МаксМаркет — это новый современный маркетплейс, где вы можете не только покупать товары,
            но и в 2 клика стать продавцом. Покупать или продавать здесь просто и удобно. Вас ждут
            лучшие цены, безупречный сервис и быстрая доставка. МаксМаркет - твой шоппинг без
            колючек!
          </Typography>
        </Fb>
        <Grid item xs={12} sx={{ height: '400px', minWidth: '1180px', maxHeight: '1440px' }}>
          <Grid
            container
            columns={20}
            justifyContent="space-between"
            sx={{ paddingTop: '30px', width: '100%' }}
          >
            <Grid item xs={12} md={7} lg={4} sx={{ maxWidth: '30%' }}>
              <Grid item sx={{ width: '280px' }}>
                <Fb column alignStart>
                  <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                    Покупателям
                  </Typography>
                  {forBuyers.map(({ link, label }: FooterLinkInterface, index) => (
                    <NavLink
                      to={link}
                      className="footer_title"
                      key={'footer-key-forBuyers-' + index}
                    >
                      {label}
                    </NavLink>
                  ))}
                </Fb>
                <Fb column alignStart sx={{ marginTop: '55px' }}>
                  <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                    Партнерам
                  </Typography>
                  {forPartners.map(({ link, label }: FooterLinkInterface, index) => (
                    <NavLink
                      className="footer_title"
                      key={'footer-key-forPartners-' + index}
                      to={link}
                    >
                      {label}
                    </NavLink>
                  ))}
                </Fb>
              </Grid>
            </Grid>
            <Grid item>
              <Fb column alignStart>
                <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                  Магазинам
                </Typography>
                {forStores.map(({ link, label }: FooterLinkInterface, index) => (
                  <NavLink to={link} className="footer_title" key={'footer-key-forBuyers-' + index}>
                    {label}
                  </NavLink>
                ))}
              </Fb>

              <Grid item>
                <Fb column alignStart sx={{ marginTop: '105px' }}>
                  <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                    Новости
                  </Typography>
                  {newsCompanies.map(({ link, label }: FooterLinkInterface, index) => (
                    <NavLink
                      to={link}
                      className="footer_title"
                      key={'footer-key-forBuyers-' + index}
                    >
                      {label}
                    </NavLink>
                  ))}
                </Fb>
              </Grid>
            </Grid>
            <Grid item>
              <Fb column alignStart>
                <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                  Наши контакты
                </Typography>
                <Fb className="contact">
                  <img src={AddressMarkIcon} alt="phone" width={16} height={16} />
                  <Typography sx={{ py: 2, marginLeft: '7px' }} fontSize={14} color="#4F4F4F">
                    Адрес: г.Одинцово, ул. Южная, д. 8а
                  </Typography>
                </Fb>

                <div className="contact_wrap">
                  <img src={MailIcon} alt="phone" width={16} height={16} />
                  <div>
                    <Link href="mailto:s@maxmarket24.ru" underline="none">
                      <Typography sx={{ py: 2, marginLeft: '7px' }} fontSize={14} color="#4F4F4F">
                        Опт и франшиза: s@maxmarket24.ru
                      </Typography>
                    </Link>

                    <Link href="mailto:retail@maxmarket24.ru" underline="none">
                      <Typography sx={{ py: 1, marginLeft: '7px' }} fontSize={14} color="#4F4F4F">
                        Розничная закупка: retail@maxmarket24.ru
                      </Typography>
                    </Link>

                    <Link href="mailto: spm@maxmarket24.ru" underline="none">
                      <Typography sx={{ py: 1, marginLeft: '7px' }} fontSize={14} color="#4F4F4F">
                        Поддержка магазинов: spm@maxmarket24.ru
                      </Typography>
                    </Link>
                  </div>
                </div>

                <div className="phone_wrap">
                  <img src={PhoneIcon} alt="phone" width={16} height={16} />
                  <div>
                    <Link href="tel:88005505728" underline="none">
                      <Typography sx={{ py: 2, marginLeft: '7px' }} fontSize={14} color="#4F4F4F">
                        Телефон: 8 (800) 550 57 28
                      </Typography>
                    </Link>
                    <Link href="tel:+7985875-32-27" underline="none">
                      <Typography sx={{ py: 2, marginLeft: '7px' }} fontSize={14} color="#4F4F4F">
                        По вопросам рекламы: +7 (985) 875-32-27
                      </Typography>
                    </Link>
                  </div>
                </div>
              </Fb>
              <Typography
                sx={{ color: '#292929', marginTop: '35px' }}
                fontSize={20}
                fontWeight={500}
              >
                Найти нас на:
              </Typography>
              <Fb
                sx={{
                  width: '130px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '5px',
                }}
              >
                <Link
                  className="vk-fb"
                  href="https://t.me/Max_Market24"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TelegramIcon} alt="VK" width="20px" height="20px" />
                </Link>
                <Link
                  className="vk-fb"
                  href="https://vk.com/maxmarket24ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={VKIcon} alt="Instagram" width="20px" height="20px" />
                </Link>
                <Link
                  className="vk-fb"
                  href="https://dzen.ru/maxmarket24"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={DzenIcon} alt="Instagram" width="20px" height="20px" />
                </Link>
              </Fb>
            </Grid>
          </Grid>
          <Box
            color="#4F4F4F"
            sx={{
              pt: 3,
              height: '100px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginTop: '30px',
            }}
          >
            <img className="payment-img" src={PaymentOptionsImage} alt="payment options" />
            <div className="hr"></div>
            <p className="footer-end">
              © 2016-{new Date().getFullYear()} Маркетплейс нового поколения “MAXMARKET”
            </p>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
