import axios, { AxiosRequestConfig } from 'axios';

import { authStorage } from 'store/storage';
import handleErrorMessage from 'helpers/handleErroreMessage';

import { REACT_APP_BUYER_URL } from 'constants/config';
import ROUTES from 'constants/routes';
import { dispatch } from 'store';
import { setMaintenanceShow } from 'store/maintenance';

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(function (config) {
  const { tokenType, accessToken } = authStorage.get();
  if (accessToken) config.headers.authorizationToken = `${tokenType} ${accessToken}`;
  return config;
});

instance.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  async (error: any) => {
    const { response: { status = 0 } = {}, config } = error;
    if (status === 503) {
      dispatch(setMaintenanceShow());
    }
    const { url: requestUrl } = config;
    const isRefreshRequest = requestUrl.includes('/refresh');
    const isLogoutRequest = requestUrl.includes('/logout');
    if (status === 401) {
      const { refreshToken } = authStorage.get();
      try {
        if (isRefreshRequest || isLogoutRequest) {
          return;
        } else {
          const { accessToken, expiresIn, tokenType }: any = await create(
            `${REACT_APP_BUYER_URL}/refresh`,
            { token: refreshToken }
          );
          if (accessToken) {
            authStorage.set({ accessToken, expiresIn, tokenType });
            return instance(error.config);
          }
        }
      } catch (err: any) {
        authStorage.remove();
        window.location.href = ROUTES.SIGN_IN;
        return Promise.reject(err);
      }
    } else {
      const { code } = error?.response?.data?.details || {};
      handleErrorMessage(code);
    }
    return Promise.reject(error);
  }
);

type ParamsType = Record<any, any>;

export const create = (path?: string, params?: ParamsType, options?: AxiosRequestConfig) =>
  instance.post(path, params, options);

export const update = async (path?: string, params?: ParamsType, options?: AxiosRequestConfig) =>
  instance.put(path, params, options);

export const edit = async (path?: string, params?: ParamsType, options?: AxiosRequestConfig) =>
  instance.patch(path, params, options);

export const read = async (path?: string, options?: AxiosRequestConfig) =>
  instance.get(path, options);

export const remove = async (path?: string, options?: AxiosRequestConfig) =>
  instance.delete(path, options);

export default {
  read,
  create,
  update,
  remove,
};
