export const {
  REACT_APP_NODE_ENV,
  REACT_APP_BUYER_URL,
  REACT_APP_SELLER_URL,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_YANDEX_MAP_KEY,
  REACT_APP_FNS_URL,
  REACT_APP_FNS_KEY,
  REACT_APP_HANDLE_FNS,
} = process.env;
