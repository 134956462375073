import { lazy } from 'react';
import ROUTES from 'constants/routes';

import Registration from 'pages/Auth/Registration';
import Login from 'pages/Auth/Login';

export const authRoutes = [
  {
    path: ROUTES.DASHBOARD,
    component: lazy(() => import('pages/Dashboard/')),
    isPrivate: false,
  },
  {
    path: ROUTES.SIGN_IN,
    component: Login,
    isPrivate: false,
  },
  {
    path: ROUTES.SIGN_UP,
    component: Registration,
    isPrivate: false,
  },
  {
    path: ROUTES.SELLER_SIGN_UP,
    component: lazy(() => import('pages/Auth/Registration/steps/')),
    isPrivate: false,
  },
  {
    path: ROUTES.ACCOUNT,
    component: lazy(() => import('pages/Account/')),
    isPrivate: true,
  },
  {
    path: ROUTES.BRANDS,
    component: lazy(() => import('pages/Brands/')),
    isPrivate: false,
  },
  {
    path: ROUTES.BRAND,
    component: lazy(() => import('pages/Brand/')),
    isPrivate: false,
  },
  {
    path: ROUTES.SEARCH,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCT_BY_CATEGORIES_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.BRAND_CATEGORIES_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.BRAND_ACTUAL_OFFERS_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },

  {
    path: ROUTES.BRAND_RANDOM_OFFERS_LIST,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCT,
    component: lazy(() => import('pages/Product/')),
    isPrivate: false,
  },
  {
    path: ROUTES.CART,
    component: lazy(() => import('pages/ShoppingCart')),
    isPrivate: false,
  },
  {
    path: ROUTES.MOBILECATALOG,
    component: lazy(() => import('sections/MobileCatalog/MobileCatalog')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCTS,
    component: lazy(() => import('pages/Products')),
    isPrivate: false,
  },
  {
    path: ROUTES.WHOLESALER_PURCHASE,
    component: lazy(() => import('pages/Purchase')),
    isPrivate: true,
  },
  {
    path: ROUTES.FOOTER,
    component: lazy(() => import('pages/FooterList')),
    isPrivate: false,
  },
  {
    path: ROUTES.PRODUCTS_CATEGORIES,
    component: lazy(() => import('pages/Category/Category')),
    isPrivate: false,
  },
  {
    path: ROUTES.NEWS_SECTION,
    component: lazy(() => import('pages/FooterList/FooterPages/NewsSection/singlePage')),
    isPrivate: false,
  },
  {
    path: ROUTES.RECOMMENDS,
    component: lazy(() => import('pages/FooterList/FooterPages/Recommends/SinglePageRecomends')),
    isPrivate: false,
  },
  {
    path: ROUTES.MAX_MONEY_INFO,
    component: lazy(() => import('pages/MaxMoneyInfo')),
    isPrivate: false,
  },
  {
    path: ROUTES.APRIL_PAGE_INFO,
    component: lazy(() => import('pages/AprilpageInfo')),
    isPrivate: false,
  },
];
