import { createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { read } from 'utils/api';
import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';

import { ProductsGetRequestParams, ProductListState } from './types';

const initialState: ProductListState = {
  data: [],
  meta: {},
  status: 'idle',
  categories: {
    filters: {
      data: [],
    },
  },
};

const getCategoryFilters = async (categoryId: string) => {
  const response = await read(`${REACT_APP_BUYER_URL}/category-filters/${categoryId}`);
  return response;
};

export const getCategoryFiltersThunk = createAsyncThunk('catergotyFilters/get', getCategoryFilters);

export const getProductListAPI = async (
  { brandID, categoryID, page, filteringUrl, limit = 15 }: ProductsGetRequestParams,
  { signal }: any
) => {
  let url = `${REACT_APP_BUYER_URL}/products?brandId=${brandID}&categoryId=${categoryID}&page=${page}&limit=${limit}`;
  if (filteringUrl) {
    url += `&${filteringUrl}`;
  }

  const response = await read(url, { signal });
  return response;
};

export const getProductByCategorisListAPI = async ({
  categoryID,
  page,
  filteringUrl,
  limit = 15,
}: ProductsGetRequestParams) => {
  let url = `${REACT_APP_BUYER_URL}/products?categoryId=${categoryID}&page=${page}&limit=${limit}`;
  if (filteringUrl) {
    url += filteringUrl;
  }
  const response = await read(url);
  return response;
};

export const getProductListSearchAPI = async (searchQuery: string) => {
  const response = await read(`${REACT_APP_BUYER_URL}/products?keyword=${searchQuery}`);
  return response;
};

export const readProductList = createAsyncThunk('productList/get', getProductListAPI);

export const readProductByCategorisList = createAsyncThunk(
  'productByCategoriList/getByCategory',
  getProductByCategorisListAPI
);

export const readSearchProductList = createAsyncThunk(
  'productList/readList',
  getProductListSearchAPI
);

export const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    removeFromWishlistInCategoryProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    changWishlistProductIdInCategoryProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    resetBrandProducts: (state: ProductListState) => {
      const { data, meta, status } = initialState;
      return {
        ...state,
        data,
        meta,
        status,
      };
    },
    resetCategoryFilters: (state: ProductListState) => {
      return {
        ...state,
        categories: initialState.categories,
      };
    },
    setCategoryFilters: (state: ProductListState, action: any) => {
      const { data, callback } = action.payload;
      callback && callback();
      return {
        ...state,
        categories: {
          filters: {
            data,
          },
        },
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCategoryFiltersThunk.fulfilled.type, (state: ProductListState, action: any) => {
        return {
          ...state,
          categories: {
            filters: {
              data: action.payload.data,
            },
          },
        };
      })
      .addCase(
        readProductList.fulfilled.type,
        (state: ProductListState, action: PayloadAction<ProductListState>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            status: 'success',
            data,
            meta,
          };
        }
      )
      .addCase(readProductList.pending.type, (state: ProductListState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(readProductList.rejected.type, (state: ProductListState) => {
        return {
          ...state,
          data: initialState.data,
          status: 'failed',
        };
      })
      .addCase(getCategoryFiltersThunk.pending.type, (state: ProductListState) => {
        return {
          ...state,
        };
      })
      .addCase(
        readProductByCategorisList.fulfilled.type,
        (state: ProductListState, action: PayloadAction<ProductListState>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            status: 'success',
            data,
            meta,
          };
        }
      )
      .addCase(readProductByCategorisList.pending.type, (state: ProductListState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(readProductByCategorisList.rejected.type, (state: ProductListState) => {
        return {
          ...state,
          data: initialState.data,
          status: 'failed',
        };
      });
  },
});

export default productListSlice.reducer;

export const useProductListData = (): ProductListState => {
  const reducerState = useAppSelector((state: RootState) => state.productList);
  return reducerState;
};

export const {
  setCategoryFilters,
  resetBrandProducts,
  resetCategoryFilters,
  changWishlistProductIdInCategoryProducts,
  removeFromWishlistInCategoryProducts,
}: any = productListSlice.actions;
