import { LazyExoticComponent } from 'react';
import { Routes, Route } from 'react-router-dom';

import { PrivateRoute, LoginAndRegistrationRoute } from 'routes';
import NotFound from 'pages/NotFound';

import routes from 'constants/routes';

type RouteParams = {
  component: LazyExoticComponent<any> | any;
  path?: string;
  isPrivate: boolean;
};

interface SwitchRouter {
  routes?: RouteParams[];
}

const renderRoute = (route: RouteParams, index: number): JSX.Element => {
  const { isPrivate, path, component: Component } = route;
  const { SIGN_IN, SIGN_UP } = routes;

  const element = isPrivate ? (
    <PrivateRoute component={Component} />
  ) : SIGN_IN === path || SIGN_UP === path ? (
    <LoginAndRegistrationRoute component={Component} />
  ) : (
    <Component />
  );

  return <Route key={index} path={path} element={element} />;
};

const SwitchRouter = ({ routes }: SwitchRouter): JSX.Element => (
  <Routes>
    <Route path="*" element={<NotFound />} />
    {routes?.map(renderRoute)}
  </Routes>
);

export default SwitchRouter;
