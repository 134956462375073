import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useShoppingCart } from 'store/cart';

import './MobileFooter.scss';

const MobileFooter = () => {
  const [active, setActive] = useState('/');

  const { count } = useShoppingCart();

  return (
    <div className="mobile_footer">
      <NavLink to="/" onClick={() => setActive('/')}>
        {active === '/' ? (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M23.121 9.55305L15.536 1.9625C14.5972 1.02593 13.3257 0.5 12 0.5C10.6743 0.5 9.40276 1.02593 8.464 1.9625L0.879011 9.55305C0.599437 9.831 0.377782 10.1617 0.226894 10.526C0.0760072 10.8903 -0.0011104 11.281 1.20795e-05 11.6753V21.4982C1.20795e-05 22.2943 0.316082 23.0578 0.878691 23.6208C1.4413 24.1837 2.20436 24.5 3.00001 24.5H21C21.7956 24.5 22.5587 24.1837 23.1213 23.6208C23.6839 23.0578 24 22.2943 24 21.4982V11.6753C24.0011 11.281 23.924 10.8903 23.7731 10.526C23.6222 10.1617 23.4006 9.831 23.121 9.55305ZM15 22.4988H9V18.5624C9 17.7663 9.31607 17.0028 9.87868 16.4399C10.4413 15.8769 11.2044 15.5606 12 15.5606C12.7956 15.5606 13.5587 15.8769 14.1213 16.4399C14.6839 17.0028 15 17.7663 15 18.5624V22.4988ZM22 21.4982C22 21.7636 21.8946 22.0181 21.7071 22.2057C21.5196 22.3934 21.2652 22.4988 21 22.4988H17V18.5624C17 17.2356 16.4732 15.963 15.5355 15.0248C14.5978 14.0865 13.3261 13.5594 12 13.5594C10.6739 13.5594 9.40215 14.0865 8.46447 15.0248C7.52679 15.963 7 17.2356 7 18.5624V22.4988H3.00001C2.73479 22.4988 2.48044 22.3934 2.2929 22.2057C2.10537 22.0181 2.00001 21.7636 2.00001 21.4982V11.6753C2.00094 11.4101 2.1062 11.156 2.29301 10.9679L9.878 3.38035C10.4417 2.81894 11.2047 2.50377 12 2.50377C12.7953 2.50377 13.5583 2.81894 14.122 3.38035L21.707 10.9709C21.8931 11.1583 21.9983 11.4112 22 11.6753V21.4982Z"
                fill="#A8509F"
              />
            </g>
            <defs>
              <clipPath id="clip0_5140_63769">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M23.121 9.55305L15.536 1.9625C14.5972 1.02593 13.3257 0.5 12 0.5C10.6743 0.5 9.40276 1.02593 8.464 1.9625L0.879011 9.55305C0.599437 9.831 0.377782 10.1617 0.226894 10.526C0.0760072 10.8903 -0.0011104 11.281 1.20795e-05 11.6753V21.4982C1.20795e-05 22.2943 0.316082 23.0578 0.878691 23.6208C1.4413 24.1837 2.20436 24.5 3.00001 24.5H21C21.7956 24.5 22.5587 24.1837 23.1213 23.6208C23.6839 23.0578 24 22.2943 24 21.4982V11.6753C24.0011 11.281 23.924 10.8903 23.7731 10.526C23.6222 10.1617 23.4006 9.831 23.121 9.55305ZM15 22.4988H9V18.5624C9 17.7663 9.31607 17.0028 9.87868 16.4399C10.4413 15.8769 11.2044 15.5606 12 15.5606C12.7956 15.5606 13.5587 15.8769 14.1213 16.4399C14.6839 17.0028 15 17.7663 15 18.5624V22.4988ZM22 21.4982C22 21.7636 21.8946 22.0181 21.7071 22.2057C21.5196 22.3934 21.2652 22.4988 21 22.4988H17V18.5624C17 17.2356 16.4732 15.963 15.5355 15.0248C14.5978 14.0865 13.3261 13.5594 12 13.5594C10.6739 13.5594 9.40215 14.0865 8.46447 15.0248C7.52679 15.963 7 17.2356 7 18.5624V22.4988H3.00001C2.73479 22.4988 2.48044 22.3934 2.2929 22.2057C2.10537 22.0181 2.00001 21.7636 2.00001 21.4982V11.6753C2.00094 11.4101 2.1062 11.156 2.29301 10.9679L9.878 3.38035C10.4417 2.81894 11.2047 2.50377 12 2.50377C12.7953 2.50377 13.5583 2.81894 14.122 3.38035L21.707 10.9709C21.8931 11.1583 21.9983 11.4112 22 11.6753V21.4982Z"
                fill="#828282"
              />
            </g>
            <defs>
              <clipPath id="clip0_5140_63769">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        )}
      </NavLink>
      <NavLink to="/catalog" onClick={() => setActive('/catalog')}>
        {active === '/catalog' ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 20.5H7"
              stroke="#A8509F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 11.5H6"
              stroke="#A8509F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 2.5H23"
              stroke="#A8509F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 20C20.0899 20 23 17.0899 23 13.5C23 9.91015 20.0899 7 16.5 7C12.9101 7 10 9.91015 10 13.5C10 17.0899 12.9101 20 16.5 20Z"
              stroke="#A8509F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.9775 22.3443L20.3668 19"
              stroke="#A8509F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 20.5H7"
              stroke="#828282"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 11.5H6"
              stroke="#828282"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 2.5H23"
              stroke="#828282"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 20C20.0899 20 23 17.0899 23 13.5C23 9.91015 20.0899 7 16.5 7C12.9101 7 10 9.91015 10 13.5C10 17.0899 12.9101 20 16.5 20Z"
              stroke="#828282"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.9775 22.3443L20.3668 19"
              stroke="#828282"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </NavLink>
      <NavLink to="/cart" onClick={() => setActive('/cart')} className="mobile_cart">
        <div className="mobile_count">{count >= 99 ? `99+` : count}</div>
        {active === '/cart' ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M22.713 4.077C22.4317 3.73944 22.0796 3.46795 21.6815 3.28182C21.2835 3.09568 20.8494 2.99946 20.41 3H4.242L4.2 2.649C4.11405 1.91942 3.76338 1.24673 3.21449 0.758478C2.6656 0.270223 1.95663 0.000341793 1.222 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H1.222C1.46693 2.00003 1.70334 2.08996 1.88637 2.25272C2.06941 2.41547 2.18634 2.63975 2.215 2.883L3.591 14.583C3.73385 15.7998 4.31848 16.9218 5.23391 17.736C6.14934 18.5502 7.33185 19 8.557 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.7348 19.8946 17.4804 19.7071 17.2929C19.5196 17.1054 19.2652 17 19 17H8.557C7.93806 16.9983 7.3348 16.8051 6.82994 16.4471C6.32507 16.089 5.94331 15.5835 5.737 15H17.657C18.8293 15.0001 19.9643 14.5882 20.8638 13.8364C21.7633 13.0846 22.37 12.0407 22.578 10.887L23.363 6.533C23.4414 6.10101 23.4237 5.65707 23.3114 5.23264C23.1991 4.80821 22.9948 4.41368 22.713 4.077ZM21.4 6.178L20.614 10.532C20.4891 11.225 20.1245 11.852 19.5839 12.3032C19.0433 12.7544 18.3612 13.0011 17.657 13H5.419L4.478 5H20.41C20.5569 4.99912 20.7022 5.03062 20.8355 5.09226C20.9689 5.15389 21.087 5.24415 21.1815 5.35661C21.276 5.46907 21.3446 5.60097 21.3824 5.74294C21.4201 5.8849 21.4262 6.03344 21.4 6.178Z"
                fill="#A8509F"
              />
              <path
                d="M7 24C8.10457 24 9 23.1046 9 22C9 20.8954 8.10457 20 7 20C5.89543 20 5 20.8954 5 22C5 23.1046 5.89543 24 7 24Z"
                fill="#A8509F"
              />
              <path
                d="M17 24C18.1046 24 19 23.1046 19 22C19 20.8954 18.1046 20 17 20C15.8954 20 15 20.8954 15 22C15 23.1046 15.8954 24 17 24Z"
                fill="#A8509F"
              />
            </g>
            <defs>
              <clipPath id="clip0_5140_63775">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M22.713 4.077C22.4317 3.73944 22.0796 3.46795 21.6815 3.28182C21.2835 3.09568 20.8494 2.99946 20.41 3H4.242L4.2 2.649C4.11405 1.91942 3.76338 1.24673 3.21449 0.758478C2.6656 0.270223 1.95663 0.000341793 1.222 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H1.222C1.46693 2.00003 1.70334 2.08996 1.88637 2.25272C2.06941 2.41547 2.18634 2.63975 2.215 2.883L3.591 14.583C3.73385 15.7998 4.31848 16.9218 5.23391 17.736C6.14934 18.5502 7.33185 19 8.557 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18C20 17.7348 19.8946 17.4804 19.7071 17.2929C19.5196 17.1054 19.2652 17 19 17H8.557C7.93806 16.9983 7.3348 16.8051 6.82994 16.4471C6.32507 16.089 5.94331 15.5835 5.737 15H17.657C18.8293 15.0001 19.9643 14.5882 20.8638 13.8364C21.7633 13.0846 22.37 12.0407 22.578 10.887L23.363 6.533C23.4414 6.10101 23.4237 5.65707 23.3114 5.23264C23.1991 4.80821 22.9948 4.41368 22.713 4.077ZM21.4 6.178L20.614 10.532C20.4891 11.225 20.1245 11.852 19.5839 12.3032C19.0433 12.7544 18.3612 13.0011 17.657 13H5.419L4.478 5H20.41C20.5569 4.99912 20.7022 5.03062 20.8355 5.09226C20.9689 5.15389 21.087 5.24415 21.1815 5.35661C21.276 5.46907 21.3446 5.60097 21.3824 5.74294C21.4201 5.8849 21.4262 6.03344 21.4 6.178Z"
                fill="#828282"
              />
              <path
                d="M7 24C8.10457 24 9 23.1046 9 22C9 20.8954 8.10457 20 7 20C5.89543 20 5 20.8954 5 22C5 23.1046 5.89543 24 7 24Z"
                fill="#828282"
              />
              <path
                d="M17 24C18.1046 24 19 23.1046 19 22C19 20.8954 18.1046 20 17 20C15.8954 20 15 20.8954 15 22C15 23.1046 15.8954 24 17 24Z"
                fill="#828282"
              />
            </g>
            <defs>
              <clipPath id="clip0_5140_63775">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </NavLink>
      <NavLink to="/account/personal-info" onClick={() => setActive('/sign-in')}>
        {active === '/sign-in' ? (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M12 12.5C13.1867 12.5 14.3467 12.1481 15.3334 11.4888C16.3201 10.8295 17.0892 9.89246 17.5433 8.7961C17.9974 7.69975 18.1162 6.49335 17.8847 5.32946C17.6532 4.16558 17.0818 3.09648 16.2426 2.25736C15.4035 1.41825 14.3344 0.846802 13.1705 0.615291C12.0067 0.38378 10.8003 0.5026 9.7039 0.956726C8.60754 1.41085 7.67047 2.17989 7.01118 3.16658C6.35189 4.15328 6 5.31331 6 6.5C6.00159 8.09081 6.63424 9.61602 7.75911 10.7409C8.88399 11.8658 10.4092 12.4984 12 12.5ZM12 2.5C12.7911 2.5 13.5645 2.7346 14.2223 3.17412C14.8801 3.61365 15.3928 4.23836 15.6955 4.96927C15.9983 5.70017 16.0775 6.50444 15.9231 7.28036C15.7688 8.05629 15.3878 8.76902 14.8284 9.32843C14.269 9.88784 13.5563 10.2688 12.7804 10.4231C12.0044 10.5775 11.2002 10.4983 10.4693 10.1955C9.73836 9.89277 9.11365 9.38008 8.67412 8.72228C8.2346 8.06449 8 7.29113 8 6.5C8 5.43914 8.42143 4.42172 9.17157 3.67158C9.92172 2.92143 10.9391 2.5 12 2.5Z"
                fill="#A8509F"
              />
              <path
                d="M12 14.5C9.61386 14.5026 7.32622 15.4517 5.63896 17.139C3.95171 18.8262 3.00265 21.1139 3 23.5C3 23.7652 3.10536 24.0196 3.29289 24.2071C3.48043 24.3946 3.73478 24.5 4 24.5C4.26522 24.5 4.51957 24.3946 4.70711 24.2071C4.89464 24.0196 5 23.7652 5 23.5C5 21.6435 5.7375 19.863 7.05025 18.5503C8.36301 17.2375 10.1435 16.5 12 16.5C13.8565 16.5 15.637 17.2375 16.9497 18.5503C18.2625 19.863 19 21.6435 19 23.5C19 23.7652 19.1054 24.0196 19.2929 24.2071C19.4804 24.3946 19.7348 24.5 20 24.5C20.2652 24.5 20.5196 24.3946 20.7071 24.2071C20.8946 24.0196 21 23.7652 21 23.5C20.9974 21.1139 20.0483 18.8262 18.361 17.139C16.6738 15.4517 14.3861 14.5026 12 14.5Z"
                fill="#A8509F"
              />
            </g>
            <defs>
              <clipPath id="clip0_5140_63777">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M12 12.5C13.1867 12.5 14.3467 12.1481 15.3334 11.4888C16.3201 10.8295 17.0892 9.89246 17.5433 8.7961C17.9974 7.69975 18.1162 6.49335 17.8847 5.32946C17.6532 4.16558 17.0818 3.09648 16.2426 2.25736C15.4035 1.41825 14.3344 0.846802 13.1705 0.615291C12.0067 0.38378 10.8003 0.5026 9.7039 0.956726C8.60754 1.41085 7.67047 2.17989 7.01118 3.16658C6.35189 4.15328 6 5.31331 6 6.5C6.00159 8.09081 6.63424 9.61602 7.75911 10.7409C8.88399 11.8658 10.4092 12.4984 12 12.5ZM12 2.5C12.7911 2.5 13.5645 2.7346 14.2223 3.17412C14.8801 3.61365 15.3928 4.23836 15.6955 4.96927C15.9983 5.70017 16.0775 6.50444 15.9231 7.28036C15.7688 8.05629 15.3878 8.76902 14.8284 9.32843C14.269 9.88784 13.5563 10.2688 12.7804 10.4231C12.0044 10.5775 11.2002 10.4983 10.4693 10.1955C9.73836 9.89277 9.11365 9.38008 8.67412 8.72228C8.2346 8.06449 8 7.29113 8 6.5C8 5.43914 8.42143 4.42172 9.17157 3.67158C9.92172 2.92143 10.9391 2.5 12 2.5Z"
                fill="#828282"
              />
              <path
                d="M12 14.5C9.61386 14.5026 7.32622 15.4517 5.63896 17.139C3.95171 18.8262 3.00265 21.1139 3 23.5C3 23.7652 3.10536 24.0196 3.29289 24.2071C3.48043 24.3946 3.73478 24.5 4 24.5C4.26522 24.5 4.51957 24.3946 4.70711 24.2071C4.89464 24.0196 5 23.7652 5 23.5C5 21.6435 5.7375 19.863 7.05025 18.5503C8.36301 17.2375 10.1435 16.5 12 16.5C13.8565 16.5 15.637 17.2375 16.9497 18.5503C18.2625 19.863 19 21.6435 19 23.5C19 23.7652 19.1054 24.0196 19.2929 24.2071C19.4804 24.3946 19.7348 24.5 20 24.5C20.2652 24.5 20.5196 24.3946 20.7071 24.2071C20.8946 24.0196 21 23.7652 21 23.5C20.9974 21.1139 20.0483 18.8262 18.361 17.139C16.6738 15.4517 14.3861 14.5026 12 14.5Z"
                fill="#828282"
              />
            </g>
            <defs>
              <clipPath id="clip0_5140_63777">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        )}
      </NavLink>
    </div>
  );
};

export default MobileFooter;
