import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { create, remove, read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import {
  increaseWishlistProductsCount,
  decreaseWishlistProductsCount,
} from 'store/buyer/editBuyerData';
import {
  changWishlistProductIdInActualOffers,
  removefromWishlistInActualOffers,
} from 'store/currentBrand/ActualOffers';
import {
  changWishlistProductIdInAllProducts,
  removeFromWishlistInAllProducts,
} from 'store/products';
import {
  changWishlistProductIdInCategoryProducts,
  removeFromWishlistInCategoryProducts,
} from 'store/brandCategory';
import {
  changWishlistProductIdInBestCashback,
  removeFromWishlistInBestCashback,
  changWishlistProductIdInRandomProducts,
  removeFromWishlistInRandomProducts,
} from 'store/dashboard';
import {
  changWishlistProductIdInSimilarProducts,
  removeFromWishlistInSimilarProducts,
} from 'store/currentProduct/SimilarProducts';
import {
  changWishlistProductInCurrentProduct,
  renoveFromWishlistInCurrentProduct,
} from 'store/currentProduct/ProductInfo';
import {
  AddToWishlistRequestParams,
  RemoveFromWishlistRequestParams,
  WishlistInterface,
} from './types';

const addToWishlist = async (
  { productId, from }: AddToWishlistRequestParams,
  { dispatch }: any
) => {
  const response = await create(`${REACT_APP_BUYER_URL}/wishlist`, {
    productId,
  });
  const { id: wishlistProductId }: any = response;

  dispatch(increaseWishlistProductsCount());
  if (from === 'bestCashback') {
    dispatch(
      changWishlistProductIdInBestCashback({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'actualOffers') {
    dispatch(
      changWishlistProductIdInActualOffers({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'canBeInterested') {
    dispatch(
      changWishlistProductIdInRandomProducts({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'products') {
    dispatch(
      changWishlistProductIdInAllProducts({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'single') {
    dispatch(
      changWishlistProductInCurrentProduct({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'similarProducts') {
    dispatch(
      changWishlistProductIdInSimilarProducts({
        productId,
        wishlistProductId,
      })
    );
  } else if (from === 'categoryProducts') {
    dispatch(
      changWishlistProductIdInCategoryProducts({
        productId,
        wishlistProductId,
      })
    );
  }
  return response;
};

export const addToWishlistThunk = createAsyncThunk('wishlist/add', addToWishlist);

const removeFromWishlist = async (
  { wishlistProductId, from }: RemoveFromWishlistRequestParams,
  { dispatch }: any
) => {
  const response = await remove(`${REACT_APP_BUYER_URL}/wishlist/${wishlistProductId}`);
  dispatch(decreaseWishlistProductsCount());

  if (from === 'bestCashback') {
    dispatch(
      removeFromWishlistInBestCashback({
        wishlistProductId,
      })
    );
  } else if (from === 'actualOffers') {
    dispatch(
      removefromWishlistInActualOffers({
        wishlistProductId,
      })
    );
  } else if (from === 'canBeInterested') {
    dispatch(
      removeFromWishlistInRandomProducts({
        wishlistProductId,
      })
    );
  } else if (from === 'products') {
    dispatch(
      removeFromWishlistInAllProducts({
        wishlistProductId,
      })
    );
  } else if (from === 'single') {
    dispatch(renoveFromWishlistInCurrentProduct());
  } else if (from === 'similarProducts') {
    dispatch(
      removeFromWishlistInSimilarProducts({
        wishlistProductId,
      })
    );
  } else if (from === 'categoryProducts') {
    dispatch(
      removeFromWishlistInCategoryProducts({
        wishlistProductId,
      })
    );
  }
  return response;
};

export const removeFromWishlistThunk = createAsyncThunk('wishlist/remove', removeFromWishlist);

const getWishlist = async (page: number) => {
  const response = await read(`${REACT_APP_BUYER_URL}/wishlist?page=${page}`);
  return response;
};

export const getWishlistThunk = createAsyncThunk('wishlist/get', getWishlist);

const initialState: WishlistInterface = {
  data: [],
  status: 'idle',
  moveProductToTheWishlistAfterOpeningThePage: false,
  meta: {},
};

const WishlistSlice = createSlice({
  initialState,
  name: 'wishlist',
  reducers: {
    moveProductToTheWishlistAfterOppenningThePageAction: (state: WishlistInterface, action) => {
      const { payload } = action;
      return {
        ...state,
        moveProductToTheWishlistAfterOpeningThePage: payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWishlistThunk.rejected.type, (state: WishlistInterface) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(getWishlistThunk.pending.type, (state: WishlistInterface) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getWishlistThunk.fulfilled.type, (state: WishlistInterface, action: AnyAction) => {
        const {
          meta: { arg: page },
          payload: { data, meta },
        } = action;

        return {
          ...state,
          status: 'success',
          data: page === 1 ? data : [...state.data, ...data],
          meta,
        };
      })
      .addCase(
        removeFromWishlistThunk.fulfilled.type,
        (state: WishlistInterface, action: AnyAction) => {
          const {
            meta: {
              arg: { wishlistProductId },
            },
          } = action;

          const newData = state.data.filter(({ id }) => id !== wishlistProductId);
          return {
            ...state,
            data: newData,
          };
        }
      );
  },
});

export const useWishlistData = (): WishlistInterface => {
  const reducerState = useAppSelector((state: RootState) => state.wishlist);
  return reducerState;
};

export const { moveProductToTheWishlistAfterOppenningThePageAction } = WishlistSlice.actions;

export default WishlistSlice.reducer;
