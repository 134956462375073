import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useOrderData } from 'store/order';
import { setShowWarningCheckDeliveryDate, useShoppingCart } from 'store/cart';
import deliveryMethods from 'constants/deliveryMethod';

import alertTriangleIcon from 'assets/icons/alert-triangle.svg';
import DeleteAlertIcon from 'assets/icons/deleteAlert.svg';

const CheckDeliveryDatesAlert = (): JSX.Element => {
  const dispatch = useDispatch();

  const { delivery } = deliveryMethods;

  const { mainAddress, deliveryMethod, orderStatus } = useOrderData();
  const { showWarningCheckDeliveryDate } = useShoppingCart();

  useEffect(() => {
    const city = localStorage.getItem('city');
    const { city: mainAddressCity } = mainAddress || {};

    if (mainAddressCity) {
      if (
        city !== mainAddressCity &&
        showWarningCheckDeliveryDate !== 'viewed' &&
        +deliveryMethod === delivery
      ) {
        dispatch(setShowWarningCheckDeliveryDate('show'));
      } else if (+deliveryMethod !== delivery) {
        dispatch(setShowWarningCheckDeliveryDate('idle'));
      }
    }
  }, [mainAddress, deliveryMethod]);

  useEffect(() => {
    if (orderStatus === 'loading') {
      dispatch(setShowWarningCheckDeliveryDate('idle'));
    }
  }, [orderStatus]);

  const closeAlert = () => {
    dispatch(setShowWarningCheckDeliveryDate('viewed'));
  };

  return (
    showWarningCheckDeliveryDate === 'show' && (
      <div className="alert-warning">
        <div className="alert-warning-info">
          <img src={alertTriangleIcon} />
          <div className="alert-body-wrap">
            <p className="alert-title">Предупреждение</p>
            <p className="alert-description">
              Введенный адрес отличается от текущего местоположения, сроки доставки могли
              измениться.
            </p>
          </div>
          <img className="cursor-pointer" onClick={closeAlert} src={DeleteAlertIcon} />
        </div>
      </div>
    )
  );
};

export default CheckDeliveryDatesAlert;
