import { createSlice, PayloadAction, createAsyncThunk, current, AnyAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import deliveryMethods from 'constants/deliveryMethod';
import paymentMethods from 'constants/paymentMethod';
import paymentTypes from 'constants/paymentType';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import { returnShoppingCartPreviousItemsState } from 'helpers/returnShoppingCartItemsPreviouesState';
import handleErrorMessage from 'helpers/handleErroreMessage';
import { create, edit } from 'utils/api';
import { getShoppingCart, changeProductStatusThunk } from 'store/cart';

import { OrderSliceInterface, OrderRequestData, CreateShoppingCartRequestData } from './types';

const changeOrderStatus = async ({ orderId }: { orderId: string }, { dispatch }) => {
  const response = await edit(`${REACT_APP_BUYER_URL}/orders/payment-status`, {
    billingOrderId: orderId,
  });
  returnShoppingCartPreviousItemsState(dispatch);
  return response;
};

export const changeOrderStatusThunk = createAsyncThunk(
  'order/changePaymentStatus',
  changeOrderStatus
);

const handleOrder = async (data: OrderRequestData, { rejectWithValue }) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/order`, data);
    return response;
  } catch (err: any) {
    const { response: { data: { details: { message = '', code = 0 } = {} } = {} } = {} } = err;
    return rejectWithValue({ message, code });
  }
};

export const orderThunk = createAsyncThunk('create', handleOrder);

const createShoppingCart = async (
  data: CreateShoppingCartRequestData,
  { dispatch, rejectWithValue }: any
) => {
  try {
    const {
      callBack,
      phoneNumber,
      products,
      otp,
      countryCode,
      restActiveProducts,
      buyNow,
      buyNowProductId,
    } = data;

    const response = await create(`${REACT_APP_BUYER_URL}/users/shopping-carts`, {
      otp,
      phoneNumber,
      products,
      countryCode,
    });

    if (response) {
      localStorage.removeItem('cartProducts');

      Object.entries(response).forEach(([key, value]) => {
        localStorage.setItem(key, `${value}`);
      });
      localStorage.setItem('phoneNumber', `${phoneNumber}`);
      phoneNumber;

      if (buyNow) {
        const [lat, lng] = JSON.parse(localStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
        const { data: cartData } = await getShoppingCart({ lat, lng });

        let activeItems = [];
        const data = [];
        Object.values(cartData).forEach((products: any) => {
          products.forEach(({ id, isActive, product: { id: productId } }) => {
            activeItems.push({ id, productId, isActive });
            if (restActiveProducts.includes(productId)) {
              data.push({
                id,
                isActive: true,
              });
            } else {
              data.push({
                id,
                isActive,
              });
            }
          });
        });

        if (activeItems.length) {
          if (buyNowProductId) {
            const index = activeItems.findIndex(
              ({ productId, isActive }) => productId === buyNowProductId && isActive
            );
            activeItems.splice(index, 1);
          }
          activeItems = activeItems.filter(({ isActive }) => isActive).map(({ id }) => id);
          if (activeItems.length) {
            const result = {
              isActive: false,
              shopingCartIds: activeItems,
            };
            dispatch(changeProductStatusThunk({ data: result }));
          }
        }
        localStorage.setItem('shoppingCartPreviousData', JSON.stringify(data));
      }
      callBack && callBack();
    }
    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const createShoppingCartThunk = createAsyncThunk('shopping-cart/create', createShoppingCart);

const initialState: OrderSliceInterface = {
  deliveryMethod: deliveryMethods.delivery,
  deliveryAddress: '',
  lat: '',
  lng: '',
  maxmoney: 0,
  paymentMethod: paymentMethods.online,
  paymentType: paymentTypes.creditCard,
  mainCard: {},
  mainAddress: {},
  mainReceiver: {},
  showCongratulationsModal: false,
  showFailureModal: false,
  shopppingCartCreated: false,
  orderId: undefined,
  withBoxberry: false,
  showCheckShoppingCartWarning: false,
  checkShoppingCartText: '',
  orderStatus: 'idle',
  showOrderModalWithoutLogingIn: false,
  otpErrorMessage: null,
  showRequiredFieldsError: {
    mainReceiver: false,
    mainAddress: false,
    deliveryAddress: false,
  },
};

const OrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setShowShoppingCartWarning: (state: OrderSliceInterface, action) => {
      const { checkShoppingCartText, showCheckShoppingCartWarning } = action.payload;
      return {
        ...state,
        showCheckShoppingCartWarning,
        checkShoppingCartText,
      };
    },
    setRequiredFildsError: (state: OrderSliceInterface, action: PayloadAction<any>) => {
      return {
        ...state,
        showRequiredFieldsError: action.payload,
      };
    },
    resetOtpErrorMessage: (state: OrderSliceInterface) => {
      const { otpErrorMessage } = initialState;
      return {
        ...state,
        otpErrorMessage,
      };
    },
    setShowOrderModalWithoutLogingIn: (state: OrderSliceInterface, action: PayloadAction<any>) => {
      return {
        ...state,
        otpErrorMessage: null,
        showOrderModalWithoutLogingIn: action.payload,
      };
    },
    changeOrderFields: (state: OrderSliceInterface, action: PayloadAction<any>) => {
      const showRequiredFieldsError = current(state).showRequiredFieldsError;
      const {
        payload: { field, value },
      } = action;

      return {
        ...state,
        showRequiredFieldsError: showRequiredFieldsError[field]
          ? { ...showRequiredFieldsError, [field]: false }
          : showRequiredFieldsError,
        [field]: value,
      };
    },
    showCongratulationsModalAction: (state: OrderSliceInterface) => {
      state.showCongratulationsModal = true;
    },
    hideCongratulationsModal: (state: OrderSliceInterface) => {
      state.showCongratulationsModal = false;
    },
    hideFailureModal: (state: OrderSliceInterface) => {
      state.showFailureModal = false;
    },
    showFailureModalAction: (state: OrderSliceInterface) => {
      state.showFailureModal = true;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createShoppingCartThunk.fulfilled.type, (state: OrderSliceInterface) => {
        return {
          ...state,
          shopppingCartCreated: true,
        };
      })
      .addCase(
        createShoppingCartThunk.rejected.type,
        (state: OrderSliceInterface, action: AnyAction) => {
          const otpErrorMessage = handleErrorMessage(action.payload);
          return {
            ...state,
            otpErrorMessage,
            shopppingCartCreated: true,
          };
        }
      )
      .addCase(orderThunk.rejected.type, (state: OrderSliceInterface, action: AnyAction) => {
        const { code = null, message = '' } = action.payload;

        const result = {
          ...state,
          orderStatus: 'rejected',
        };
        if (code === 623) {
          result.showCheckShoppingCartWarning = true;
        }
        if (code == 800) {
          toast.error(message);
        }
        return result;
      })
      .addCase(orderThunk.pending.type, (state: OrderSliceInterface) => {
        return {
          ...state,
          orderStatus: 'loading',
        };
      })
      .addCase(orderThunk.fulfilled.type, (state: OrderSliceInterface, action: any) => {
        const {
          payload: { id, formUrl = '' },
        } = action;

        localStorage.removeItem('orderNotes');
        if (formUrl) {
          window.open(formUrl, '_self', 'noreferrer');
        }

        return {
          ...state,
          showCongratulationsModal: formUrl ? false : true,
          shopppingCartCreated: false,
          orderId: id,
          orderStatus: 'success',
        };
      });
  },
});

export const useOrderData = (): OrderSliceInterface => {
  const reducerState = useAppSelector((state: RootState) => state.order);
  return reducerState;
};

export default OrderSlice.reducer;
export const {
  setShowOrderModalWithoutLogingIn,
  changeOrderFields,
  hideCongratulationsModal,
  showCongratulationsModalAction,
  reset,
  hideFailureModal,
  setRequiredFildsError,
  setShowShoppingCartWarning,
  showFailureModalAction,
  resetOtpErrorMessage,
} = OrderSlice.actions;
