import { createAsyncThunk, createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';

import { create } from 'utils/api';
import { authStorage } from 'store/storage';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { Status } from 'store/types';
import handleErrorMessage from 'helpers/handleErroreMessage';

import { SignUpResponse } from './types';

export interface RegistrState {
  userData: SignUpResponse;
  status: Status;
  buyerRegistrErrorMessage: null | string;
}

const initialState: RegistrState = {
  userData: {},
  status: 'idle',
  buyerRegistrErrorMessage: null,
};

export const checkOTPBuyerRegister = async ({ data, callBack }, { rejectWithValue }: any) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/register`, data);
    callBack && callBack();
    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const checkOTPBuyerRegisterThunk = createAsyncThunk(
  'buyerRegister/checkOtp',
  checkOTPBuyerRegister
);

export const BuyerRegistrationSlice = createSlice({
  name: 'buyerRegistration',
  initialState,
  reducers: {
    resetbuyerRegistrErrorMessage: (state: RegistrState) => {
      const { buyerRegistrErrorMessage } = initialState;
      return {
        ...state,
        buyerRegistrErrorMessage,
      };
    },
    resetBuyerRegistration: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(checkOTPBuyerRegisterThunk.pending.type, (state: RegistrState) => {
        state.status = 'loading';
      })
      .addCase(
        checkOTPBuyerRegisterThunk.fulfilled.type,
        (state: RegistrState, action: PayloadAction<SignUpResponse>) => {
          state.status = 'success';
          if (action.payload?.accessToken) {
            authStorage.set(action.payload);
            state.buyerRegistrErrorMessage = null;
            state.userData = action.payload;
          }
        }
      )
      .addCase(
        checkOTPBuyerRegisterThunk.rejected.type,
        (state: RegistrState, action: AnyAction) => {
          const buyerRegistrErrorMessage = handleErrorMessage(action.payload);
          return {
            ...state,
            buyerRegistrErrorMessage,
            status: 'failed',
          };
        }
      );
  },
});

export const { resetBuyerRegistration, resetbuyerRegistrErrorMessage } =
  BuyerRegistrationSlice.actions;
export default BuyerRegistrationSlice.reducer;
