import { createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { Meta, Status } from 'store/types';
import { RootState } from 'store/index';

import { ActualOffer, ActualOffersRequest } from './types';

interface ActualOffersState {
  data: Array<ActualOffer>;
  meta: Meta;
  status: Status;
}

const initialState = {
  data: [],
  meta: {},
  status: 'idle',
} as ActualOffersState;

const getActualOffers = (
  { limit = 15, page = 1, filteringUrl, brandID }: ActualOffersRequest,
  { signal }: any
) => {
  let url = `${REACT_APP_BUYER_URL}/products?limit=${limit}&page=${page}`;

  if (filteringUrl) {
    url += `&${filteringUrl}`;
  }
  if (brandID) {
    url += `&brandId=${brandID}`;
  } else {
    url += `&isActual=1`;
  }
  return read(url, { signal });
};

export const getActualOffersThunk = createAsyncThunk('currentBrand/actualOffers', getActualOffers);

const ActualOffersSlice = createSlice({
  name: 'ActualOffer',
  initialState,
  reducers: {
    removefromWishlistInActualOffers: (state: ActualOffersState, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));

      return {
        ...state,
        data,
      };
    },
    changWishlistProductIdInActualOffers: (state: ActualOffersState, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    resetActualOffer() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getActualOffersThunk.fulfilled.type,
        (state: ActualOffersState, action: PayloadAction<ActualOffersState>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data,
            meta,
            status: 'success',
          };
        }
      )
      .addCase(getActualOffersThunk.pending.type, (state: ActualOffersState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getActualOffersThunk.rejected.type, (state: ActualOffersState) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
});

export const useActualOfferData = (): ActualOffersState => {
  const reducerState = useAppSelector((state: RootState) => state.currentBrand.actualOffers);
  return reducerState;
};

export const {
  resetActualOffer,
  changWishlistProductIdInActualOffers,
  removefromWishlistInActualOffers,
} = ActualOffersSlice.actions;
export default ActualOffersSlice.reducer;
