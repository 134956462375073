import VkontakteIcon from 'assets/icons/vkheader.svg';
import TelegramIcon from 'assets/icons/telegramIcon.svg';
import DzenIcon from 'assets/icons/dzen.svg';

export const socialLinks = [
  {
    Icon: VkontakteIcon,
    href: 'https://vk.com/maxmarket24ru',
    alt: 'vk',
  },
  {
    Icon: TelegramIcon,
    href: 'https://t.me/Max_Market24',
    alt: 'telegram',
  },
  {
    Icon: DzenIcon,
    href: 'https://dzen.ru/maxmarket24',
    alt: 'dzen',
  },
];
