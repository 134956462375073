import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Brand } from './types';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { Meta, Status } from 'store/types';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';

const getBrands = async (page: number) => {
  const limit = page === 1 ? 30 : 15;
  const response = await read(`${REACT_APP_BUYER_URL}/brands?page=${page}&limit=${limit}`);
  return response;
};

interface BrandsState {
  data: Array<Brand>;
  meta: Meta;
  status: Status;
}

export const getBrandsData = createAsyncThunk('brands/list', getBrands);

const initialState = { data: [], meta: {}, status: 'idle' } as BrandsState;

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    resetBrands: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(
        getBrandsData.fulfilled.type,
        (state: BrandsState, action: PayloadAction<BrandsState>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            status: 'success',
            data: [...state.data, ...data],
            meta,
          };
        }
      )
      .addCase(getBrandsData.pending.type, (state: BrandsState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getBrandsData.rejected.type, (state: BrandsState) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
});

export const useBrandsData = (): BrandsState => {
  const reducerState = useAppSelector((state: RootState) => state.brands);
  return reducerState;
};

export const { resetBrands } = brandsSlice.actions;

export default brandsSlice.reducer;
