import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'moment/locale/ru';
import { ToastContainer } from 'react-toastify';

import StoreProvider from 'store/StoreProvider';
import PagesWrapper from 'pages/PagesWrapper';
import ThemeProvider from 'theme/ThemeProvider';
import ToasterProvider from 'components/Toaster';
import RedirectComponent from 'components/RedirectComponent';
// import DevelopmentWarningModal from 'components/DevelopmentWarningModal';
import ScrollToTop from 'components/ScrollToTop';
import MaxMoneyInfoModal from 'components/MaxMoneyInfoModal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import './App.scss';
import './alerts.scss';

const App = (): React.ReactElement => {
  useEffect(() => {
    document.addEventListener('gesturestart', event => {
      event.preventDefault();
    });
  }, []);
  return (
    <>
      <ToasterProvider>
        <StoreProvider>
          <ThemeProvider>
            <Router>
              <ScrollToTop />
              <MaxMoneyInfoModal />
              <RedirectComponent />
              {/* <DevelopmentWarningModal /> */}
              <ToastContainer
                position="top-right"
                autoClose={5000}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <PagesWrapper />
            </Router>
          </ThemeProvider>
        </StoreProvider>
      </ToasterProvider>
    </>
  );
};

export default App;
