import { FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { Typography, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Fb from 'components/FB';
import {
  getCatalogCategoriesListUrl,
  getProductByCategoriesListUrl,
} from 'helpers/getProductByCategoriesList';
import { Banner } from 'store/banners/types';
import { Category } from 'store/catalog/types';

interface Props {
  subCategory: Category;
  banner: Banner;
  subRef: any;
  handleCloseCatalogMenu: () => void;
}

const SubCategories: FC<Props> = ({ subCategory, banner, subRef, handleCloseCatalogMenu }) => {
  const handleSubCategories = (id: number, name?: string) => {
    const breadCrumbsData = [
      {
        id: 1,
        to: name ? getProductByCategoriesListUrl(id) : '',
        title: subCategory.name,
      },
    ];
    if (name) {
      breadCrumbsData.push({
        id: 2,
        to: '',
        title: name,
      });
    }

    return {
      to: getProductByCategoriesListUrl(id),
      state: { breadCrumbsData },
    };
  };

  const renderSubCategories = subCategories => {
    return (
      <ul key={uuidv4()}>
        {subCategories?.data
          ?.filter(e => e.isActive)
          .map(subItem => {
            if (subItem.subCategories.data) {
              return renderSubCategories(subItem.subCategories);
            }
            const { id, name } = subItem;
            return (
              <li
                key={id}
                onClick={e => {
                  e.stopPropagation();
                  handleCloseCatalogMenu();
                }}
                className="sub__item_name"
              >
                <Link {...{ ...handleSubCategories(id, name) }}>{subItem.name}</Link>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <>
      <Grid item className="catalog__sub">
        <Typography className="title">{subCategory?.name}</Typography>

        <div className="sub__wrapper" ref={subRef}>
          <ul className="sub__list">
            {subCategory?.subCategories?.data
              ?.filter(e => e.isActive)
              .map(subCategoryItem => (
                <li
                  key={subCategoryItem.id}
                  className="sub__item"
                  onClick={e => {
                    e.stopPropagation();
                    handleCloseCatalogMenu();
                  }}
                >
                  <Link to={getCatalogCategoriesListUrl(subCategoryItem.id)}>
                    <span className="sub__item_parent_name cursor-pointer">
                      <span className="sub_parent">{subCategoryItem.name}</span>
                    </span>
                  </Link>
                  {renderSubCategories(subCategoryItem.subCategories)}
                </li>
              ))}
          </ul>
        </div>
      </Grid>

      <Grid item>
        <Fb className="catalog__banner_wrapper">
          {!!banner && (
            <img
              src={banner.path}
              onClick={() => window.open(banner.url || '/', '_blank')}
              alt="category logo"
              className="catalog__img cursor-pointer"
            />
          )}
        </Fb>
      </Grid>
    </>
  );
};

export default memo(SubCategories);
