import { createAsyncThunk, createSlice, PayloadAction, current } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { Product } from 'store/currentProduct/types';
import { Meta } from 'store/types';

interface SimilarProductsState {
  data: Array<Product>;
  meta: Meta;
}

const getSimilarProducts = async ({
  brandId,
  categoryId,
  productId,
}: {
  brandId: number;
  categoryId: number;
  productId: number;
}) => {
  const response = await read(
    `${REACT_APP_BUYER_URL}/products?brandId=${brandId}&categoryId=${categoryId}&productId=${productId}`
  );
  return response;
};

export const getSimilarProductsInfo = createAsyncThunk('similar-products', getSimilarProducts);

const initialState = { data: [], meta: {} } as SimilarProductsState;

const similarProductsSlice = createSlice({
  name: 'similarProducts',
  initialState,
  reducers: {
    removeFromWishlistInSimilarProducts: (state: SimilarProductsState, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    changWishlistProductIdInSimilarProducts: (state: SimilarProductsState, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getSimilarProductsInfo.fulfilled.type,
      (state: SimilarProductsState, action: PayloadAction<SimilarProductsState>) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          data,
          meta,
        };
      }
    );
  },
});

export const useSimilarProductsData = (): SimilarProductsState => {
  const reducerState = useAppSelector((state: RootState) => state.currentProduct.similarProducts);
  return reducerState;
};

export const { changWishlistProductIdInSimilarProducts, removeFromWishlistInSimilarProducts } =
  similarProductsSlice.actions;
export default similarProductsSlice.reducer;
