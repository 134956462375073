import { useEffect, useState, useRef, memo } from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';
import { YMaps, Map, ZoomControl, GeolocationControl, Placemark } from 'react-yandex-maps';

import { AppDispatch } from 'store';
import { setRefreshCart } from 'store/cart';
import Modal from 'components/Modal';
import Fb from 'components/FB';
import ErrorViewYandexMap from 'components/ErrorViewYandexMap';
import getAddressName, { defaultCity, getAddressDetails } from 'helpers/getAddressNameByCoords';
import { REACT_APP_YANDEX_MAP_KEY } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';

import { ReactComponent as AddressMarkIcon } from 'assets/icons/address-marker.svg';

const GeolocationMap = () => {
  const dispatch = useDispatch<AppDispatch>();
  const mapRef = useRef<any>();
  const addresInputRef = useRef<HTMLInputElement>(null);
  const [showMap, setShowMap] = useState(false);
  const [errorViewMap, setErrorViewMap] = useState<boolean>(false);
  const [location, setLocation] = useState<any>({
    city: '',
    address: '',
    latitude: '',
    longitude: '',
  });
  const [possibleLocation, setPossibleLocation] = useState<any>({
    city: '',
    address: '',
    latitude: '',
    longitude: '',
  });
  const [mapState, setMapState] = useState({
    center: [MOSCOW_LAT, MOSCOW_LNG],
    zoom: 9,
  });

  const selectedCity = localStorage.getItem('city');
  const selectedCoordinates = localStorage.getItem('GPS');

  useEffect(() => {
    const { latitude, longitude, city } = location;

    if (latitude && longitude) {
      localStorage.setItem('GPS', JSON.stringify([latitude, longitude]));
      localStorage.setItem('city', city);
      dispatch(setRefreshCart());
    }
  }, [location]);

  useEffect(() => {
    if (selectedCity) {
      setLocation({ address: selectedCity, city: selectedCity, latitude: '', longitude: '' });
    } else if (navigator.geolocation && !selectedCity) {
      navigator.geolocation.getCurrentPosition(
        async ({ coords }) => {
          const { latitude, longitude } = coords;
          const { city, address, countryCode } = await getAddressName(
            [latitude, longitude],
            'city'
          );

          const addressCoordinates = {
            latitude,
            longitude,
          };

          if (countryCode !== 'RU') {
            addressCoordinates.latitude = MOSCOW_LAT;
            addressCoordinates.longitude = MOSCOW_LNG;
          }

          setLocation({
            city,
            address,
            latitude: addressCoordinates.latitude,
            longitude: addressCoordinates.longitude,
          });
          setPossibleLocation({
            city,
            address,
            latitude: addressCoordinates.latitude,
            longitude: addressCoordinates.longitude,
          });
        },
        () => {
          setLocation({
            address: 'Москва',
            city: 'Москва',
            latitude: MOSCOW_LAT,
            longitude: MOSCOW_LNG,
          });
          setPossibleLocation({
            address: 'Москва',
            city: 'Москва',
            latitude: MOSCOW_LAT,
            longitude: MOSCOW_LNG,
          });
        }
      );
    } else {
      setLocation({
        address: 'Москва',
        city: 'Москва',
        latitude: MOSCOW_LAT,
        longitude: MOSCOW_LNG,
      });
      setPossibleLocation({
        address: 'Москва',
        city: 'Москва',
        latitude: MOSCOW_LAT,
        longitude: MOSCOW_LNG,
      });
    }
  }, [selectedCity]);

  const closeModal = () => {
    setShowMap(false);
    setErrorViewMap(false);
    setPossibleLocation({
      address: '',
      latitude: '',
      longitude: '',
      city: '',
    });
  };

  const openMapModal = () => {
    setShowMap(true);
  };

  const loadSuggest = async ymaps => {
    const suggestView = new ymaps.SuggestView('suggest');

    const { latitude, longitude, city } = (await getAddressDetails(location.address, false)) || {};
    let coordinates;

    try {
      coordinates = JSON.parse(selectedCoordinates);
    } catch (err) {
      coordinates = [];
    }

    const [selectedLatitude, selectedLongitude] = coordinates;
    const lat = selectedLatitude || latitude;
    const long = selectedLongitude || longitude;
    if (lat && long) {
      setMapState({
        ...mapState,
        center: [lat, long],
      });
      setPossibleLocation({
        address: location.address,
        city,
        latitude: lat,
        longitude: long,
      });
    }

    suggestView.events.add('select', async e => {
      const address = e.get('item');

      const { value } = address;
      const {
        latitude,
        longitude,
        city,
        address: fullAddress,
      } = (await getAddressDetails(value, false)) || {};
      if (latitude && longitude) {
        setPossibleLocation({ address: fullAddress, city, latitude, longitude });
        setMapState({
          ...mapState,
          center: [+latitude, +longitude],
        });
      }
    });
  };

  const setPossibleLocationToLocation = () => {
    const { address, latitude, longitude, city } = possibleLocation;
    setLocation({ address, city, latitude, longitude });
    closeModal();
  };

  const onMapClick = async e => {
    const coords = e.get('coords');

    const [latitude, longitude] = coords;
    const { city, address, countryCode } = await getAddressName([latitude, longitude], 'city');

    setMapState({
      ...mapState,
      center: [+latitude, +longitude],
    });

    const addressCoordinates = {
      latitude,
      longitude,
    };

    if (countryCode !== 'RU') {
      addressCoordinates.latitude = MOSCOW_LAT;
      addressCoordinates.longitude = MOSCOW_LNG;
    }

    setPossibleLocation({
      address,
      city,
      latitude: addressCoordinates.latitude,
      longitude: addressCoordinates.longitude,
    });

    if (addresInputRef.current) {
      addresInputRef.current.value = address;
    }
  };

  const getCurrentLocation = async e => {
    try {
      e.stopPropagation();
      const [latitude, longitude] = e.originalEvent.position;

      setMapState({
        ...mapState,
        center: [+latitude, +longitude],
      });

      const { city, address, countryCode } = await getAddressName([latitude, longitude], 'city');

      const addressCoordinates = {
        latitude,
        longitude,
      };

      if (countryCode !== 'RU') {
        addressCoordinates.latitude = MOSCOW_LAT;
        addressCoordinates.longitude = MOSCOW_LNG;
      }
      setPossibleLocation({
        address,
        city,
        latitude: addressCoordinates.latitude,
        longitude: addressCoordinates.longitude,
      });

      if (addresInputRef.current) {
        addresInputRef.current.value = address;
      }
    } catch (err) {
      setPossibleLocation({
        address: defaultCity,
        city: defaultCity,
        latitude: MOSCOW_LAT,
        longitude: MOSCOW_LNG,
      });
      if (addresInputRef.current) {
        addresInputRef.current.value = defaultCity;
      }
    } finally {
      if (mapRef?.current) {
        mapRef.current.setZoom(15, { duration: 500 });
      }
    }
  };

  const handleErrorViewMap = () => {
    setErrorViewMap(true);
  };

  return (
    <>
      <Fb className="location-wrap">
        <Fb className="location-info" gap="9px" onClick={openMapModal}>
          <AddressMarkIcon className="addres-mark" />
          <Typography className="location">{location.city || 'Москва'}</Typography>
        </Fb>

        <Typography className="loc-name">Принимаем заказы по всей России</Typography>
      </Fb>

      <Modal bodyClassName="geolocation-map" open={showMap} onClose={closeModal}>
        <Fb className="h-100">
          <Fb className="address-part" column justifyBetween alignStretch>
            <div>
              <h2 className="mapModalTitle"> Адрес доставки</h2>
              <input
                type="text"
                defaultValue={possibleLocation.city || location.city}
                color="warning"
                id="suggest"
                className="address-input"
                placeholder="Адрес"
                ref={addresInputRef}
              />
            </div>
            <button
              className="setLocationBtn setLocationBtn-web"
              onClick={setPossibleLocationToLocation}
              disabled={!possibleLocation.address || possibleLocation.address === selectedCity}
            >
              Сохранить
            </button>
          </Fb>
          {errorViewMap ? (
            <ErrorViewYandexMap />
          ) : (
            <YMaps enterprise query={{ apikey: REACT_APP_YANDEX_MAP_KEY }}>
              <Map
                onError={handleErrorViewMap}
                onClick={onMapClick}
                onLoad={ymaps => loadSuggest(ymaps)}
                width="736px"
                height="534px"
                state={{ ...mapState, center: [+mapState.center[0], +mapState.center[1]] }}
                instanceRef={(ref: any) => {
                  mapRef.current = ref;
                }}
                modules={['SuggestView']}
              >
                {possibleLocation.latitude && possibleLocation.longitude && (
                  <Placemark geometry={[+possibleLocation.latitude, +possibleLocation.longitude]} />
                )}
                <GeolocationControl
                  options={{
                    noPlacemark: true,
                  }}
                  onLocationChange={getCurrentLocation}
                />
                <ZoomControl />
              </Map>
            </YMaps>
          )}
          <button
            className="setLocationBtn setLocationBtn-mobile"
            onClick={setPossibleLocationToLocation}
            disabled={!possibleLocation.address || possibleLocation.address === selectedCity}
          >
            Сохранить
          </button>
        </Fb>
      </Modal>
    </>
  );
};

export default memo(GeolocationMap);
