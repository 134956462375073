import { Box, Modal } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ModalInterface } from './types';

const style = {
  position: { xs: 'relative', md: 'absolute' },
  top: { xs: '0', md: '50%' },
  left: { xs: '0', md: '50%' },
  width: { xs: 'calc(100% - 16px)', md: 'inherit' },
  height: { xs: 'calc(100% - 16px)', md: 'inherit' },
  transform: { xs: 'none', md: 'translate(-50%, -50%)' },
  minWidth: { xs: 'none', md: 400 },
  background: '#FFFFFF',
  borderRadius: '8px',
  padding: { xs: '16px', md: '40px' },
  margin: { xs: '8px', md: '0' },

  '&:focus-visible': {
    outline: 'none',
  },

  '& .close-modal': {
    position: 'absolute',
    width: 40,
    height: 40,
    cursor: 'pointer',
    zIndex: 1,
  },

  '& .close-modal-1': {
    right: -18,
    top: -18,
  },

  '& .close-modal-2': {
    right: 40,
    top: 40,
  },

  '& .close-modal-1,.close-modal-2': {
    svg: {
      width: 'inherit',
      height: 'inherit',
    },
    '&:hover': {
      svg: {
        path: {
          stroke: '#828282',
        },
      },
    },
  },
};

const CustomModal = ({
  open,
  onClose,
  children,
  className,
  closeIconVersion = 1,
  bodyClassName,
  showCloseIcon = true,
}: ModalInterface) => {
  return (
    <Modal open={open} onClose={onClose} className={className}>
      <Box sx={style} className={bodyClassName}>
        {showCloseIcon && (
          <span onClick={onClose} className={`close-modal close-modal-${closeIconVersion}`}>
            <CloseIcon />
          </span>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
