import { combineReducers } from 'redux';
import categories from './BrandCategories';
import info from './BrandInfo';
import actualOffers from './ActualOffers';

const BrandReducer = combineReducers({
  categories,
  info,
  actualOffers,
});

export default BrandReducer;
