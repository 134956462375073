import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';

import { GetOfferProductsParams, OfferProductsState } from './types';

const getOfferProducts = async ({ itemCode, productId }: GetOfferProductsParams) => {
  const response = await read(`${REACT_APP_BUYER_URL}/similar-products/${itemCode}/${productId}`);
  return response;
};

export const getOfferProductsThunk = createAsyncThunk('offerProducts/get', getOfferProducts);

const initialState = { data: [], meta: {}, status: 'idle' } as OfferProductsState;

const offerProductsSlice = createSlice({
  name: 'offerProducts',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(
        getOfferProductsThunk.fulfilled.type,
        (state: OfferProductsState, action: PayloadAction<OfferProductsState>) => {
          const { data } = action.payload;
          return {
            ...state,
            data,
            status: 'success',
          };
        }
      )
      .addCase(getOfferProductsThunk.pending.type, (state: OfferProductsState) => {
        return {
          ...state,
          status: 'loading',
        };
      });
  },
});

export const useProductOffersData = (): OfferProductsState => {
  const reducerState = useAppSelector((state: RootState) => state.currentProduct.offerProducts);
  return reducerState;
};

export const { reset } = offerProductsSlice.actions;
export default offerProductsSlice.reducer;
