import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { OneOrder } from './types';
import { getBuyerOneOrder } from './api';

export const getBuyerOneOrderDataThunk = createAsyncThunk('buyerOneOrder', getBuyerOneOrder);

const initialState: OneOrder = {
  id: null,
  createdAt: '',
  totalPrice: 0,
  status: null,
  receiverName: '',
  isRefunded: false,
  statusLoading: 'idle',
  products: {
    data: [],
  },
  deliveryMethod: {
    address: null,
    city: null,
    id: null,
    lat: '',
    lng: '',
    method: 0,
    paymentMethod: 0,
    paymentType: 0,
    isRefunded: false,
  },
};

const buyerOneOrderSlice = createSlice({
  name: 'buyerOneOrder',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getBuyerOneOrderDataThunk.fulfilled.type,
        (state: OneOrder, action: PayloadAction<OneOrder>) => {
          return {
            ...state,
            ...action.payload,
            statusLoading: 'success',
          };
        }
      )
      .addCase(
        getBuyerOneOrderDataThunk.pending.type,
        (state: OneOrder, action: PayloadAction<OneOrder>) => {
          return {
            ...state,
            ...action.payload,
            statusLoading: 'loading',
          };
        }
      );
  },
});

export const useBuyerOneOrderData = (): OneOrder => {
  const reducerState = useAppSelector((state: RootState) => state.buyer.buyerOneOrder);
  return reducerState;
};

export const { reset } = buyerOneOrderSlice.actions;

export default buyerOneOrderSlice.reducer;
