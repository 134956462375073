import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';

import { Status } from 'store/types';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { Product, GetSimilarProductParams } from 'store/currentProduct/types';

interface ProductState {
  data: Product;
  status: Status;
  durationGetLoading: Status;
  productCountInCart: number;
}

interface OneProductRequestInterface {
  id: string;
  lat: string;
  lng: string;
}
const getProduct = async ({ id, lat, lng }: OneProductRequestInterface) => {
  const response = await read(`${REACT_APP_BUYER_URL}/products/${id}?lat=${lat}&lng=${lng}`);
  return response;
};

const getProductDuration = async ({ id, lat, lng }: OneProductRequestInterface) => {
  const responseNoDeliveryZone = await read(
    `${REACT_APP_BUYER_URL}/in-no-delivery-zone?lat=${lat}&lng=${lng}`
  );
  const { outOfDelivery } = responseNoDeliveryZone as any;

  if (outOfDelivery) {
    return { duration: 4 };
  }
  const response = await read(
    `${REACT_APP_BUYER_URL}/products/${id}/duration?lat=${lat}&lng=${lng}`
  );
  return response;
};

export const getProductInfo = createAsyncThunk('product', getProduct);

export const getProductDurationThunk = createAsyncThunk('productDuration/get', getProductDuration);

export const getProductCountInCart = async (id: string) => {
  const response = await read(`${REACT_APP_BUYER_URL}/products/${id}/stock`);
  return response;
};

export const getProductCountInCartThunk = createAsyncThunk(
  'productCountInCart/get',
  getProductCountInCart
);

const getSimilarProduct = async ({
  productId,
  filterName,
  filterValue,
}: GetSimilarProductParams) => {
  let url = `${REACT_APP_BUYER_URL}/same-products/${productId}`;

  if (filterName && filterValue) {
    url += `?&${filterName}=${filterValue}`;
  }
  const response = await read(url);

  return response;
};

export const getSimilarProductThunk = createAsyncThunk('sameProduct/get', getSimilarProduct);

const initialState = {
  data: {},
  status: 'idle',
  durationGetLoading: 'idle',
  productCountInCart: 0,
} as ProductState;

const currentProductInfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    changWishlistProductInCurrentProduct: (state: ProductState, action) => {
      const {
        payload: { wishlistProductId },
      } = action;
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductId,
        },
      };
    },
    renoveFromWishlistInCurrentProduct: (state: ProductState) => {
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductId: null,
        },
      };
    },
    resetProduct: () => initialState,
    setSimilarProduct: (state: ProductState, action: any) => {
      return { ...state, data: action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getProductInfo.fulfilled.type,
        (state: ProductState, action: PayloadAction<ProductState>) => {
          return {
            ...state,
            data: { ...state.data, ...action.payload },
            status: 'success',
          };
        }
      )
      .addCase(getProductInfo.pending.type, (state: ProductState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getProductInfo.rejected.type, (state: ProductState) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(getProductDurationThunk.fulfilled.type, (state: ProductState, action: any) => {
        const { duration } = action.payload;
        return {
          ...state,
          data: {
            ...state.data,
            duration,
          },
          durationGetLoading: 'success',
        };
      })
      .addCase(getProductDurationThunk.pending.type, (state: ProductState) => {
        return {
          ...state,
          durationGetLoading: 'loading',
        };
      })
      .addCase(getProductDurationThunk.rejected.type, (state: ProductState) => {
        return {
          ...state,
          durationGetLoading: 'failed',
        };
      })
      .addCase(getProductCountInCartThunk.fulfilled.type, (state: ProductState, action: any) => {
        const { countInCart: productCountInCart = 0 } = action.payload;
        return {
          ...state,
          productCountInCart,
        };
      });
  },
});

export const useCurrentProductInfoData = (): ProductState => {
  const reducerState = useAppSelector((state: RootState) => state.currentProduct.info);
  return reducerState;
};

export const {
  resetProduct,
  setSimilarProduct,
  changWishlistProductInCurrentProduct,
  renoveFromWishlistInCurrentProduct,
} = currentProductInfoSlice.actions;

export default currentProductInfoSlice.reducer;
