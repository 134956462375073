import { combineReducers } from 'redux';

import editBuyerData from './editBuyerData';
import buyerOrders from './orders';
import buyerOneOrder from './oneOrder';
import mactuses from './Mactuses';
import maxmoneyHistory from './maxmoneyHistory';

const BuyerReducer = combineReducers({
  editBuyerData,
  buyerOrders,
  buyerOneOrder,
  mactuses,
  maxmoneyHistory,
});

export default BuyerReducer;
