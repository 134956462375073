export default {
  DASHBOARD: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SELLER_SIGN_UP: '/seller/sign-up',
  ACCOUNT: '/account/:type',
  BRANDS: '/brands',
  BRAND: '/brand/:brandID',
  CATEGORIES: '/categories',
  BRAND_CATEGORIES_LIST: '/brand/:brandID/categories/:categoryID',
  PRODUCT_BY_CATEGORIES_LIST: '/categories/:categoryID',
  BRAND_ACTUAL_OFFERS_LIST: '/actual',
  BRAND_RANDOM_OFFERS_LIST: '/interested',
  PRODUCT: '/product/:id',
  CART: '/cart',
  SEARCH: '/search',
  PRODUCTS: '/products',
  WHOLESALER_PURCHASE: '/purchase',
  ORDERS: '/account/orders',
  NOT_FOUND: '/error/404',
  FOOTER: '/info/:type',
  PRODUCTS_CATEGORIES: '/category/:categoryID',
  NEWS_SECTION: '/news-section/:id',
  RECOMMENDS: '/recommends/:id',
  CONFIDENTIALITY: '/info/confidentiality',
  MOBILECATALOG: '/catalog',
  MAX_MONEY_INFO: 'maxmoney-info',
  APRIL_PAGE_INFO: 'april-1st',
};
