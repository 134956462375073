import InstagramIcon from 'assets/icons/instagram.svg';
import VKIcon from 'assets/icons/vkontakte.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import AddressMarkIcon from 'assets/icons/address-marker.svg';
import MailIcon from 'assets/icons/mail.svg';
import ArrowPrevIcon from 'assets/icons/vector-prev.svg';
import ArrowNextIcon from 'assets/icons/vector-next.svg';

export interface FooterLinkInterface {
  label: string;
  link: string;
}

export const footerLinks = {
  forBuyers: [
    {
      label: 'Положение о персональных данных',
      link: 'info/regulation-personal-data',
    },
    {
      label: 'Доставка и оплата',
      link: 'info/shipping-payment',
    },
    {
      label: 'Полезная информация',
      link: 'info/secure-payment',
    },
    {
      label: 'Конфиденциальность персональной информации',
      link: 'info/confidentiality',
    },
  ],
  forStores: [
    {
      label: 'Как продавать на МаксМаркет?',
      link: 'info/how-to-sell',
    },
    {
      label: 'МаксМаркет рекомендует',
      link: 'info/recommends',
    },
    {
      label: 'Договор оферты',
      link: 'info/contract-offer',
    },
  ],
  forPartners: [
    {
      label: 'Оптовая закупка',
      link: 'info/wholesaler-purchase',
    },
  ],
  newsCompanies: [
    {
      label: 'Новости компании',
      link: 'info/news-section',
    },
  ],
};

export const footerIcons = {
  InstagramIcon,
  VKIcon,
  PhoneIcon,
  AddressMarkIcon,
  MailIcon,
  ArrowNextIcon,
  ArrowPrevIcon,
};
