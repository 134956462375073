import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

import RadioButtonCheckedIcon from 'assets/icons/radioButtonChecked.svg';
import RadioButtonDisabled from 'assets/icons/radioButtonDisabled.svg';

interface IRadio {
  disabled: boolean;
}

const RadioButtonChecked = (props: IRadio) => {
  const { disabled } = props;
  const src = disabled ? RadioButtonDisabled : RadioButtonCheckedIcon;
  return <img src={src} alt="" />;
};

const Icon = styled('span')(() => ({
  padding: '0px',
  background: '#FBFBFC',
  border: '1.5px solid #EAEAEA',
  borderRadius: '50%',
  height: '20px',
  width: '20px',
  'input:hover ~ &': {
    border: '1.5px solid #A8509F',
  },
  'input:disabled ~ &': {
    background: '#FBFBFC',
    border: '1.5px solid #D9D9D9',
  },
}));

const RadioButton = (props: RadioProps) => {
  const { disabled } = props;
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioButtonChecked disabled={disabled} />}
      icon={<Icon />}
      {...props}
    />
  );
};

export default RadioButton;
