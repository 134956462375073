import axios from 'axios';

import { REACT_APP_YANDEX_MAP_KEY } from 'constants/config';

export const defaultCity = 'Москва';

const getAddressName = async (coords, type = '') => {
  const { data: { response: { GeoObjectCollection: { featureMember = [] } = {} } = {} } = {} } =
    await axios.get(
      `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${REACT_APP_YANDEX_MAP_KEY}&geocode=${coords
        .reverse()
        .join(',')}`
    );
  const { GeoObject: { metaDataProperty: { GeocoderMetaData: { Address = {} } = {} } = {} } = {} } =
    featureMember[0] || [];
  const { formatted = '', Components } = Address;
  const returedData = {
    city: defaultCity,
    address: formatted,
  } as { city: string; address: string; countryCode: string };

  if (type === 'city') {
    const { country_code } = Address;

    returedData.countryCode = country_code;

    if (country_code === 'RU') {
      const locality = Components.find(({ kind }) => kind === 'locality');
      const area = Components.find(({ kind }) => kind === 'area');
      const province = Components.find(({ kind }) => kind === 'province');

      returedData.city = locality?.name || area?.name || province?.name || defaultCity;
      return returedData;
    } else {
      returedData.city = defaultCity;
      returedData.address = defaultCity;
      return returedData;
    }
  }
  return formatted;
};

export const getAddressDetails = async (address, showDetails = false) => {
  const { data: { response: { GeoObjectCollection: { featureMember = [] } = {} } = {} } = {} } =
    await axios.get(
      `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${REACT_APP_YANDEX_MAP_KEY}&geocode=${address}`
    );

  const {
    GeoObject: {
      metaDataProperty: {
        GeocoderMetaData: {
          Address: { Components, postal_code = '', country_code, formatted },
        },
      },
      Point: { pos = '' } = {},
    },
  } = featureMember[0] || {};

  if (country_code === 'RU') {
    const city =
      Components.find(({ kind }) => kind === 'locality')?.name ||
      Components.find(({ kind }) => kind === 'province')?.name ||
      '';

    if (!city) {
      return;
    }

    const [longitude, latitude] = pos.split(' ');
    const returedData: any = {
      latitude,
      longitude,
      city,
      address: formatted,
    };

    if (showDetails) {
      const street = Components.find(({ kind }) => kind === 'street')?.name || '';
      const house = Components.find(({ kind }) => kind === 'house')?.name || '';

      returedData.street = street;
      returedData.building = house;
      returedData.zipCode = postal_code;
    }
    return returedData;
  }
};

export default getAddressName;
