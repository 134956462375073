import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { Status } from 'store/types';
import { read, update } from 'utils/api';
import { setCount } from 'store/cart';

import { UserDataEditParams, UserDataResponse } from './types';

export type UserDataActions = {
  read: () => void;
  updateData: (data: UserDataEditParams) => void;
};

export interface UserDataState {
  data: UserDataResponse;
  status: Status;
  updateUserDataSuccess: boolean;
}

const initialState: UserDataState = {
  data: {},
  status: 'idle',
  updateUserDataSuccess: false,
};

const readUserData = async (_, { dispatch }) => {
  const response: any = await read(`${REACT_APP_BUYER_URL}/users/my`);
  const { productCount } = response;
  dispatch(setCount(+productCount));
  return response;
};

export const readUserDataThunk = createAsyncThunk('userData/readData', readUserData);

const updateUserDataAPI = (params: unknown) => update(`${REACT_APP_BUYER_URL}/users/my `, params);

export const updateUserData = createAsyncThunk('userData/updateData', updateUserDataAPI);

export const editBuyerDataSlice = createSlice({
  name: 'editUserData',
  initialState,
  reducers: {
    increaseWishlistProductsCount: (state: UserDataState) => {
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductCount: (state.data.wishlistProductCount || 0) + 1,
        },
      };
    },
    decreaseWishlistProductsCount: (state: UserDataState) => {
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductCount: (state.data.wishlistProductCount || 0) - 1,
        },
      };
    },
    resetUpdateUserStatus: (state: UserDataState) => ({
      ...state,
      updateUserDataSuccess: false,
    }),
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(readUserDataThunk.pending.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(
        readUserDataThunk.fulfilled.type,
        (state: UserDataState, action: PayloadAction<UserDataResponse>) => {
          return {
            ...state,
            status: 'success',
            data: action.payload,
          };
        }
      )
      .addCase(readUserDataThunk.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          data: initialState.data,
          status: 'failed',
        };
      })
      .addCase(updateUserData.pending.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'loading',
          updateUserDataSuccess: false,
        };
      })
      .addCase(updateUserData.fulfilled.type, (state: UserDataState, action: any) => {
        const { data } = state;
        return {
          ...state,
          status: 'success',
          data: {
            ...data,
            avatar: action.meta.arg.avatarPath,
          },
          updateUserDataSuccess: true,
        };
      })
      .addCase(updateUserData.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          data: initialState.data,
          status: 'failed',
          updateUserDataSuccess: false,
        };
      });
  },
});

export const useBuyerData = (): UserDataState => {
  const reducerState = useAppSelector((state: RootState) => state.buyer.editBuyerData);
  return reducerState;
};

export const {
  increaseWishlistProductsCount,
  decreaseWishlistProductsCount,
  resetUpdateUserStatus,
  reset,
} = editBuyerDataSlice.actions;

export default editBuyerDataSlice.reducer;
