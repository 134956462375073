import { createSlice, createAsyncThunk, current, AnyAction } from '@reduxjs/toolkit';

import { changeOrderFields } from 'store/order';
import { setOutOfDelivery, checkOutOfDelivery } from 'store/cart';
import { create, read, update, remove } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { Address } from 'sections/DeliveryMethod/Delivery/DeliveryAddresses/types';

import { DeliveryAddressesState, DeliveryAddressRequestInterface } from './types';

const setMainDeliveryAddress = async (data: Address, { dispatch }) => {
  const { lat, lng } = data;

  const response = await update(`${REACT_APP_BUYER_URL}/main-delivery-address`, {
    addressId: data.id,
  });

  const outOfDelivery = await checkOutOfDelivery({ lat, lng });

  dispatch(
    changeOrderFields({
      field: 'mainAddress',
      value: {
        ...data,
        isMain: true,
      },
    })
  );

  dispatch(setOutOfDelivery(outOfDelivery));
  return response;
};

const getDeliveryAddresses = async (_, { dispatch }) => {
  const response = await read(`${REACT_APP_BUYER_URL}/delivery-addresses?limit=100`);
  const { data } = response;

  const mainAddress = data.find(({ isMain }) => isMain);
  const { lat, lng } = mainAddress || {};

  if (lat && lng) {
    const outOfDelivery = await checkOutOfDelivery({ lat, lng });
    dispatch(setOutOfDelivery(outOfDelivery));
  }

  dispatch(
    changeOrderFields({
      field: 'mainAddress',
      value: mainAddress || {},
    })
  );

  return response;
};

const addDeliveryAddress = async (data: DeliveryAddressRequestInterface, { dispatch }) => {
  const response: any = await create(`${REACT_APP_BUYER_URL}/delivery-addresses`, data);
  const { id } = response;

  const { lat, lng } = data || {};

  if (lat && lng) {
    const outOfDelivery = await checkOutOfDelivery({ lat, lng });
    dispatch(setOutOfDelivery(outOfDelivery));
  }

  dispatch(
    changeOrderFields({
      field: 'mainAddress',
      value: { ...data, id, isMain: true },
    })
  );

  return response;
};

const getUserAddresses = async () => {
  const response = await read(`${REACT_APP_BUYER_URL}/users/addresses`);
  return response;
};

const deleteDeliveryAddresse = async (id: number) => {
  const response = await remove(`${REACT_APP_BUYER_URL}/delivery-addresses/${id}`);
  return response;
};

export const deleteDeliveryAddresseThunk = createAsyncThunk(
  'deliveryAddress/delete',
  deleteDeliveryAddresse
);

export const addDeliveryAddressThunk = createAsyncThunk('address/add', addDeliveryAddress);
export const getDeliveryAddressesThunk = createAsyncThunk('address/get', getDeliveryAddresses);
export const setMainDeliveryAddressThunk = createAsyncThunk(
  'address/setMain',
  setMainDeliveryAddress
);
export const getUsersAddressesThunk = createAsyncThunk('usersAddresses', getUserAddresses);

const initialState: DeliveryAddressesState = {
  data: [],
  shopAddreesses: [],
  meta: {},
};

const newAddressData = (state, mainAddressId) => {
  const addressesCopy = [...state];
  return addressesCopy.map(address => {
    const { id } = address;
    return {
      ...address,
      isMain: mainAddressId === id,
    };
  });
};

const deliveryAddress = createSlice({
  name: 'deliveryAddress',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        deleteDeliveryAddresseThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: any) => {
          const {
            meta: { arg: addresId },
          } = action;
          const { data } = current(state);

          const deliveryAddres = data.filter(e => e.id !== addresId);
          return {
            ...state,
            data: deliveryAddres,
          };
        }
      )
      .addCase(
        addDeliveryAddressThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: AnyAction) => {
          const {
            payload: { id },
            meta: { arg },
          } = action;

          const data = newAddressData([{ ...arg, id }, ...state.data], id);
          return {
            ...state,
            data,
          };
        }
      )
      .addCase(
        getDeliveryAddressesThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: any) => {
          const {
            payload: { data, meta },
          } = action;
          return {
            ...state,
            data,
            meta,
          };
        }
      )
      .addCase(
        setMainDeliveryAddressThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: any) => {
          const { id } = action.meta.arg;
          const data = newAddressData(state.data, id);
          return {
            ...state,
            data,
          };
        }
      )
      .addCase(
        getUsersAddressesThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: AnyAction) => {
          const { data } = action.payload;
          const shopAddreesses = data.filter(({ latitude, longitude }) => latitude && longitude);

          return {
            ...state,
            shopAddreesses,
          };
        }
      );
  },
});

export const useUserDeliveryAddressData = (): DeliveryAddressesState => {
  const reducerState = useAppSelector((state: RootState) => state.user.deliveryAddress);
  return reducerState;
};

export const { reset } = deliveryAddress.actions;

export default deliveryAddress.reducer;
