import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch } from 'store';
import { setShowShoppingCartWarning, useOrderData } from 'store/order';
import routes from 'constants/routes';

import alertTriangleIcon from 'assets/icons/alert-triangle.svg';
import DeleteAlertIcon from 'assets/icons/deleteAlert.svg';

const { CART } = routes;

const CheckShoppingCart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { showCheckShoppingCartWarning, checkShoppingCartText } = useOrderData();

  const moveToShoppingCart = () => {
    closeAlert();
    navigate(CART, {
      state: {
        buyNow: false,
        refreshThePage: Date.now(),
      },
    });
  };

  const closeAlert = () => {
    dispatch(setShowShoppingCartWarning(false));
  };

  return (
    showCheckShoppingCartWarning && (
      <div className="alert-warning check-shopping-cart">
        <div className="alert-warning-info">
          <img src={alertTriangleIcon} />
          <div className="alert-body-wrap">
            <p className="alert-title">Предупреждение</p>
            <div className="alert-description">
              {checkShoppingCartText ? (
                checkShoppingCartText
              ) : (
                <>
                  <span>
                    Извините, но один или несколько товаров, находящихся в Вашей корзине более
                    недоступны. Перейдите в корзину, чтобы посмотреть, какие товары доступны для
                    оформления.
                  </span>
                  <span className="cursor-pointer moveToCart" onClick={moveToShoppingCart}>
                    Проверить корзину
                  </span>
                </>
              )}
            </div>
          </div>
          <img className="cursor-pointer" onClick={closeAlert} src={DeleteAlertIcon} />
        </div>
      </div>
    )
  );
};

export default CheckShoppingCart;
