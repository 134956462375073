import { useState, useEffect } from 'react';

import classNames from 'classnames';

import { defaultParamsPreRegistration } from 'constants/auth/preRegistration';
import { defaultParamsPreLogin } from 'constants/auth/preLogin';
import { useAppDispatch } from 'store/hooks';
import { resetLoginErrorMessage } from 'store/auth/login';
import {
  checkPhonePreLoginThunk,
  preAuthLoggedOutThunk,
  usePreLoginData,
} from 'store/auth/preLogin';
import { checkPhonePreRegistrationThunk } from 'store/auth/preRegistration';
import { resetbuyerRegistrErrorMessage } from 'store/auth/registration/buyerRegistration';
import { resetSellerRegistrErrorMessage } from 'store/auth/registration/sellerOTP';
import { resetOtpErrorMessage } from 'store/order';

import './styles.scss';

const pad = n => ('0' + n).slice(-2);

const millisecondsToTimer = ms => {
  if (ms < 0) {
    return '0:00';
  }
  const minutes = Math.floor(ms / 60000);
  const seconds = pad(Math.floor((ms - minutes * 60000) / 1000));
  return `${minutes}:${seconds}`;
};

interface Props {
  phoneNumber: number | string;
  type?: number;
  orderWithoutLoggingIn?: boolean;
  onChangeOtp: (value: string) => void;
}

const OtpTimer = ({
  phoneNumber,
  type,
  orderWithoutLoggingIn,
  onChangeOtp,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [end, setEnd] = useState<number>();
  const [timer, setTimer] = useState<string>('');
  const [forward, setForward] = useState<boolean>(false);

  const { phoneNumber: newPhoneNumber } = usePreLoginData();

  const start = () => setEnd(Date.now() + 1 * 60000 + 100);

  useEffect(() => {
    start();
    return () => {
      setForward(false);
    };
  }, [forward]);

  useEffect(() => {
    if (!end) {
      return;
    }
    const t = setInterval(() => setTimer(millisecondsToTimer(end - Date.now())), 1000);

    setTimer(millisecondsToTimer(end - Date.now()));
    return () => clearInterval(t);
  }, [end]);

  const hendleForwardCode = () => {
    if (orderWithoutLoggingIn) {
      dispatch(resetOtpErrorMessage());
      dispatch(
        preAuthLoggedOutThunk({
          phoneNumber: +newPhoneNumber,
          rememberMe: true,
          ...defaultParamsPreLogin,
        })
      );
    } else {
      if (type) {
        dispatch(resetbuyerRegistrErrorMessage());
        dispatch(resetSellerRegistrErrorMessage());
        dispatch(
          checkPhonePreRegistrationThunk({
            phoneNumber: +phoneNumber,
            ...defaultParamsPreRegistration,
          })
        );
      } else {
        dispatch(resetLoginErrorMessage());
        dispatch(checkPhonePreLoginThunk({ phoneNumber: +phoneNumber, ...defaultParamsPreLogin }));
      }
    }
    onChangeOtp('');
    setForward(true);
  };

  const [minutes, seconds] = timer.split(':');

  return timer === '0:00' ? (
    <span
      onClick={hendleForwardCode}
      className={classNames('forward-code-text', {
        orderWithoutLoggingIn,
      })}
    >
      Отправить повторно
    </span>
  ) : (
    <span
      className={classNames('timer', {
        danger: +minutes == 0 && +seconds <= 5,
      })}
    >
      {timer}
    </span>
  );
};

export default OtpTimer;
