import { useLocation } from 'react-router-dom';

import { useDashboardData } from 'store/dashboard';
import { useActualOfferData } from 'store/currentBrand/ActualOffers';

import ROUTES from 'constants/routes';

import MaxMarketLogo from 'assets/icons/logoloader.svg';

import './style.scss';

const MaxMarketMobileLoading = () => {
  const { pathname } = useLocation();
  const { status } = useActualOfferData();

  const {
    banners: { status: bannerStatus },
    pageLoading,
  } = useDashboardData();

  const { DASHBOARD } = ROUTES;

  const checkLoading =
    (status === 'loading' || bannerStatus === 'loading') && pathname === DASHBOARD && !pageLoading;

  return (
    <>
      {checkLoading && (
        <div className="mobile_loading">
          <img src={MaxMarketLogo} width={300} height={100} />
          <div className="box">
            <div className="container">
              <span className="circle"></span>
              <span className="circle"></span>
              <span className="circle"></span>
              <span className="circle"></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MaxMarketMobileLoading;
