import { FC, useMemo, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Menu, Typography, IconButton } from '@mui/material';
import classNames from 'classnames';

import { logoutThunk } from 'store/auth/login';
import { authStorage } from 'store/storage';
import { removeFromShoppingCartThunk } from 'store/cart';
import Fb from 'components/FB';
import { accountPageByType } from 'helpers/routeHelper';
import { returnShoppingCartPreviousItemsState } from 'helpers/returnShoppingCartItemsPreviouesState';
import accountTabs from 'constants/accountTabs';
import userTypes from 'constants/userTypes';
import ROUTES from 'constants/routes';

import UserIcon from 'assets/icons/userHeader.svg';
import defaultMactus from 'assets/images/mactus001.png';
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
import { ReactComponent as ExitIcon } from 'assets/icons/exit.svg';
import { ReactComponent as MenuUserIcon } from 'assets/icons/menuUser.svg';
import { ReactComponent as MenuOrderListIcon } from 'assets/icons/menuOrderList.svg';
import { ReactComponent as MenuFeedbackIcon } from 'assets/icons/menuFeedback.svg';

interface Props {
  buyerAvatar: string;
  sellerAvatar: string;
  shopLogo: string;
}

const ProfileMenu: FC<Props> = ({ buyerAvatar, sellerAvatar, shopLogo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { buyer, shop, franchise, wholesaler } = userTypes;
  const { SIGN_IN } = ROUTES;
  const { userType } = authStorage.get();
  const isLoggedIn = !!authStorage.get().accessToken;
  const isBuyerType = userType === buyer;
  const isShopType = userType === shop;
  const isFranchiseType = userType === franchise;
  const isWholesalerType = userType === wholesaler;
  const accountActionPath = isLoggedIn ? accountPageByType(accountTabs.PERSONAL_INFO) : SIGN_IN;

  let image = UserIcon;

  if (isShopType) {
    image = shopLogo;
  } else if (isBuyerType) {
    image = buyerAvatar;
  } else if (isFranchiseType || isWholesalerType) {
    image = sellerAvatar;
  }

  const showUserMenu = Boolean(anchorElUser);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    handleCloseUserMenu();
    returnShoppingCartPreviousItemsState(dispatch, false);
    const buyNowShoppingCartId = +localStorage.getItem('buyNowShoppingCartId');
    if (buyNowShoppingCartId) {
      dispatch(removeFromShoppingCartThunk({ shopingCartIds: [buyNowShoppingCartId] }));
      localStorage.removeItem('buyNowShoppingCartId');
    }
    localStorage.removeItem('city');
    localStorage.removeItem('GPS');
    dispatch(logoutThunk(() => navigate(SIGN_IN)));
  };

  const handleClickOrderList = () => {
    if (isBuyerType) {
      navigate('/account/orders');
    } else {
      navigate('/account/wholesaler-orders');
    }
  };

  const handleRedirectToWishlist = () => {
    navigate('/account/wishlist');
  };

  const handleFeedbackItem = () => {
    navigate('/account/connect');
  };

  const handleClickMenuItem = () => {
    navigate(accountActionPath);
  };

  const ProfileMenuItems = useMemo(() => {
    const items = [
      {
        title: 'Личные данные',
        Icon: MenuUserIcon,
        onClick: handleClickMenuItem,
      },
      {
        title: 'Список заказов',
        Icon: MenuOrderListIcon,
        onClick: handleClickOrderList,
      },
      {
        title: 'Обратная связь',
        Icon: MenuFeedbackIcon,
        onClick: handleFeedbackItem,
      },
    ];

    if (isBuyerType) {
      items.splice(2, 0, {
        title: 'Избранное',
        Icon: HeartIcon,
        onClick: handleRedirectToWishlist,
      });
    }
    return items;
  }, [isBuyerType]);

  return (
    <>
      <IconButton
        sx={{ p: 0, width: '44px', height: '44px' }}
        onClick={isLoggedIn ? handleOpenUserMenu : () => navigate(SIGN_IN)}
        disableRipple
      >
        <img
          width={24}
          height={24}
          src={image || defaultMactus}
          className={classNames({
            'header-user-image': isBuyerType || isWholesalerType || isFranchiseType || isShopType,
          })}
          alt=""
        />
      </IconButton>

      <Menu
        className="menu-bar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showUserMenu}
        onClose={handleCloseUserMenu}
      >
        <div className="menu-wrap">
          {isLoggedIn && (
            <>
              {ProfileMenuItems?.map(({ title, Icon, onClick }) => (
                <Fb
                  key={title}
                  className="menu-item cursor-pointer"
                  onClick={() => {
                    handleCloseUserMenu();
                    onClick();
                  }}
                >
                  <div className="active-indicator" />
                  <div className="title-wrapper">
                    <Icon />
                    <Typography>{title}</Typography>
                  </div>
                </Fb>
              ))}
              <Typography className="menu-arrow" />
              <Fb className="menu-item cursor-pointer" onClick={logout}>
                <div className="active-indicator" />
                <div className="title-wrapper">
                  <ExitIcon />
                  <Typography>Выйти</Typography>
                </div>
              </Fb>
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default memo(ProfileMenu);
