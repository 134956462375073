import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ProductListState, ProductsSearch, FilteredBrandsRequest } from './types';

const getProducts = async (
  { limit = 15, search, page }: ProductsSearch,
  { rejectWithValue, signal }: any
) => {
  try {
    let url = `${REACT_APP_BUYER_URL}/products?page=${page}&limit=${limit}`;

    if (search) {
      url += `&${search}`;
    }

    const response = await read(url, { signal });
    return response;
  } catch (err: any) {
    return rejectWithValue(err.response);
  }
};

export const getProductsThunk = createAsyncThunk('productsList/get', getProducts);

const getCategoryFilters = async (categoryIds: string) => {
  const response = await read(`${REACT_APP_BUYER_URL}/category-filters/${categoryIds}`);
  return response;
};

export const getCategoryFiltersThunk = createAsyncThunk(
  'productsList/getFilters',
  getCategoryFilters
);

interface MaxPriceRequest {
  categoryId?: number;
  brandId?: number;
}
const getProductsMaxPrice = async ({ categoryId, brandId }: MaxPriceRequest) => {
  const params: any = {};
  if (categoryId) {
    params.categoryId = categoryId;
  }
  if (categoryId) {
    params.brandId = brandId;
  }
  const response = await read(`${REACT_APP_BUYER_URL}/products/max-price`, {
    params,
  });
  return response;
};

const getFilteredBrands = async ({ page, filter }: FilteredBrandsRequest) => {
  let url = `${REACT_APP_BUYER_URL}/brands?page=${page}`;
  if (filter.trim()) {
    url += `&${filter}`;
  }
  const response = await read(url);
  return response;
};

export const getFilteredBrandsThunk = createAsyncThunk('filteredBrands/get', getFilteredBrands);

const getActiveTags = async (page: number) => {
  const response = await read(`${REACT_APP_BUYER_URL}/active-tags?page=${page}`);
  return response;
};

export const getActiveTagsThunk = createAsyncThunk('activeTags/get', getActiveTags);

export const getProductsMaxPriceThunk = createAsyncThunk('products/maxPrice', getProductsMaxPrice);

const initialState: ProductListState = {
  data: [],
  meta: {},
  maxPrice: 0,
  getProductsErrorCode: null,
  status: 'idle',
  filters: [],
  activeTags: { data: [], meta: {}, status: 'idle' },
  filtrationBrands: { data: [], meta: {}, status: 'idle' },
  resetedRangeField: '',
};

const ProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    removeFromWishlistInAllProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));

      return {
        ...state,
        data,
      };
    },
    changWishlistProductIdInAllProducts: (state: ProductListState, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));

      return {
        ...state,
        data,
      };
    },
    resetProducts: (state: ProductListState) => {
      const { meta, data, status, getProductsErrorCode } = initialState;
      return {
        ...state,
        meta,
        data,
        status,
        getProductsErrorCode,
      };
    },
    resetFilteredBrands: (state: ProductListState) => {
      return {
        ...state,
        filtrationBrands: initialState.filtrationBrands,
      };
    },
    resetFilters: (state: ProductListState) => {
      return {
        ...state,
        filters: initialState.filters,
      };
    },
    resetActiveTags: (state: ProductListState) => {
      const { activeTags } = initialState;
      return {
        ...state,
        activeTags,
      };
    },
    resetRangeValue: (state: ProductListState, action) => {
      return {
        ...state,
        resetedRangeField: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProductsThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          data,
          meta,
          status: 'success',
        };
      })
      .addCase(getProductsThunk.pending.type, (state: ProductListState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getProductsThunk.rejected.type, (state: ProductListState, action: any) => {
        const { data } = action?.payload || {};
        const { details: { code = null } = {} } = data || {};
        return {
          ...state,
          status: 'failed',
          getProductsErrorCode: code,
        };
      })
      .addCase(getProductsMaxPriceThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { maxPrice } = action.payload;
        return {
          ...state,
          maxPrice,
        };
      })
      .addCase(getCategoryFiltersThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { data } = action.payload;
        return {
          ...state,
          filters: data,
        };
      })
      .addCase(getFilteredBrandsThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          filtrationBrands: {
            data: meta.currentPage === 1 ? data : [...state.filtrationBrands.data, ...data],
            meta,
            status: 'success',
          },
        };
      })
      .addCase(getFilteredBrandsThunk.pending.type, (state: ProductListState) => {
        return {
          ...state,
          filtrationBrands: {
            ...state.filtrationBrands,
            status: 'loading',
          },
        };
      })
      .addCase(getFilteredBrandsThunk.rejected.type, (state: ProductListState) => {
        return {
          ...state,
          filtrationBrands: {
            ...state.filtrationBrands,
            status: 'failed',
          },
        };
      })
      .addCase(getActiveTagsThunk.fulfilled.type, (state: ProductListState, action: any) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          activeTags: { data: [...state.activeTags.data, ...data], meta, status: 'success' },
        };
      })
      .addCase(getActiveTagsThunk.pending.type, (state: ProductListState) => {
        return {
          ...state,
          activeTags: {
            ...state.activeTags,
            status: 'loading',
          },
        };
      })
      .addCase(getActiveTagsThunk.rejected.type, (state: ProductListState) => {
        return {
          ...state,
          activeTags: {
            ...state.activeTags,
            status: 'failed',
          },
        };
      });
  },
});

export const useProductsData = (): ProductListState => {
  const reducerState = useAppSelector((state: RootState) => state.products);
  return reducerState;
};

export default ProductsSlice.reducer;
export const {
  resetRangeValue,
  resetProducts,
  resetFilteredBrands,
  resetFilters,
  changWishlistProductIdInAllProducts,
  removeFromWishlistInAllProducts,
  resetActiveTags,
} = ProductsSlice.actions;
