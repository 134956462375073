import { useNavigate } from 'react-router-dom';

import ROUTES from 'constants/routes';
import Fb from 'components/FB';

import notFound from 'assets/icons/notFound.svg';
import notFoundMactus from 'assets/icons/notFoundMactus.svg';

import './style.scss';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="notFoundPage">
      <Fb wrap justifyCenter>
        <Fb column className="text-block">
          <div className="title">Упс! Ошибка 404</div>
          <div className="description"> Что-то пошло не так!</div>
          <div className="definition">
            Страница, которую вы ищете, могла быть удалена из-за изменения названия или временно
            недоступна.
          </div>
          <button onClick={() => navigate(ROUTES.DASHBOARD)} className="returnHomeButton">
            Вернуться в главную
          </button>
        </Fb>
        <div className="imagesBlock">
          <img className="notFoundMactus" src={notFoundMactus} alt="" />
          <img className="notFoundSvg" src={notFound} alt="" />
        </div>
      </Fb>
    </div>
  );
};

export default NotFound;
